import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { associateActions } from "./store";
import { Form, Input, Button, Select } from "antd";
import { Row, Col } from "antd";
import { AssociateStatus } from "./associate-status";
import { globalActions } from "store/loader";
import '../../index.css'

const CompanyFeedbackForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { feedback, associate } = useSelector((state) => state.associate);
  const { loader } = useSelector((state) => state.global);

  useEffect(() => {
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get("/associate/company-feedback-form/?userId=" + user.id)
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(associateActions.updateFeedback({ feedback: response }));
        }
      });
  }, []);

  const FormInput = ({ id, label, type, ratings }) => {
    let ratingEl;
    if (type === "rating") {
      const Options = ratings?.map((item, index) => {
        return (
          <Select.Option key={index} value={item.value}>
            {item.label}
          </Select.Option>
        );
      });
      ratingEl = (
        <Form.Item
          label="Rating"
          name={[id, "rating"]}
          rules={[{ required: true, message: "Rating is required" }]}
        >
          <Select placeholder="0">{Options}</Select>
        </Form.Item>
      );
    }
    let remarkEl;
    if (type === "input") {
      remarkEl = (
        <Form.Item
          label="Comment"
          name={[id]}
          rules={[
            { required: true, message: "Please fill the required fields" },
            { min: 15, message: "Type minimum 15 characters" },
          ]}
        >
          <Input
            style={{ width: "50%" }}
            placeholder="Type minimum 15 characters"
          />
        </Form.Item>
      );
    }
    return (
      <div>
        <Row>
          <Col span={24}>
            {" "}
            <p style={{ color: "brown" }}>{label}</p>
            {ratingEl}
            {remarkEl}
          </Col>
        </Row>
      </div>
    );
  };

  const handleOnFinish = (values) => {
    Api.post("/associate/company-feedback-form/?userId=" + user.id)
      .params({
        companyFeedback: values,
      })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          dispatch(
            associateActions.updateAssociate({
              associate: {
                ...associate,
                ...{ userStatus: AssociateStatus.completed },
              },
            })
          );
        }
      });
  };
  const compQuestions = feedback?.feedbackQuestions?.map((question) => {
    return (
      <Col span={11} offset={1} key={question.key}>
        <FormInput
          key={question.key}
          label={question.label}
          type={question.type}
          id={question.label}
          ratings={feedback?.rating}
        ></FormInput>
      </Col>
    );
  });
  if (loader === true) {
    return null;
  } else {
    return (
      <Form
        name="basic"
        layout="vertical"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={(values) => handleOnFinish(values)}
        autoComplete="off"
      >
        <div className="contained">
          <h1 style={{ color: "brown" }}>{`Company's Feedback`}</h1>
          <hr className="solid"></hr>
          <>
            <Row>{compQuestions}</Row>
          </>
        </div>
        <div style={{paddingBottom:'1rem'}}>
          <Form.Item
            wrapperCol={{
              offset: 1,
              span: 12,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>{" "}
            {/* <Button htmlType="submit">Back</Button> */}
          </Form.Item>
        </div>
      </Form>
    );
  }
};

export default CompanyFeedbackForm;
