import React, { useState, useEffect } from "react";
// import "antd/dist/antd.css";
import "../../index.css";
import {
  Form,
  Space,
  Button,
  Menu,
  Input,
  Layout,
  Empty,
  Modal,
  Typography,
  Card,
} from "antd";
import { managerActions } from "./store";
import Api from "services/Api";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "components/associate-feedback";
import { useHistory, useLocation } from "react-router-dom";
import { globalActions } from "store/loader";
import { CheckCircleFilled } from "@ant-design/icons";
const { Title } = Typography;

const { Sider, Content } = Layout;

const ManagerAppraisalForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedTeamId, selectedAssociateId } = useSelector(
    (state) => state.manager
  );
  const { loader } = useSelector((state) => state.global);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [rating, updateRating] = useState("");
  const [comment, updateComment] = useState(null);
  const [showSubmit, setShowSubmit] = useState(false);
  const [isSubmitModal, setSubmitModal] = useState(false);

  const { appraisal } = useSelector((state) => state.manager);

  useEffect(() => {
    if (!selectedAssociateId) {
      history.goBack();
      return;
    }
    setCurrentQuestion(null);
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(
      "/manager/appraisal-form?associateId=" +
        selectedAssociateId +
        "&teamId=" +
        selectedTeamId
    )
      .params({})
      .send(async (response, error) => {
        if (typeof response != "undefined") {
          dispatch(managerActions.updateAppraisal({ appraisal: response }));
          const question = appraisal?.questions[setCurrentQuestionIndex];
          if (question) {
            let tempObj = {
              key: question.key,
              label: question.label,
              type: question.type,
              id: question.key,
              value: question.value,
              completed: question.completed,
            };
            setCurrentQuestion(tempObj);
            updateComment(question.comment);
            updateRating(question.rating);
          }
        }
      });
    return () => {
      console.log("unmount");
      setCurrentQuestion(null);
      setCurrentQuestionIndex(null);
      updateRating("");
      updateComment(null);
      setShowSubmit(false);
      setSubmitModal(false);
      dispatch(
        managerActions.updateAppraisal({
          appraisal: { questions: [], rating: [] },
        })
      );
    };
  }, []);

  console.log("hello log", showSubmit);

  useEffect(() => {
    let count = 0;
    appraisal?.questions?.forEach((question) => {
      if (question.completed) {
        count += 1;
      }
    });
    console.log("count", count, appraisal?.questions?.length);
    if (count == appraisal?.questions?.length - 1) {
      setShowSubmit(true);
    }
  }, [appraisal]);

  const isQuesDisabled = () => {
    if (currentQuestion.type == "input") {
      if (typeof comment != "undefined" && comment?.length >= 15) {
        return false;
      } else {
        return true;
      }
    } else {
      if (rating && typeof comment != "undefined" && comment?.length >= 15) {
        return false;
      } else {
        return true;
      }
    }
  };

  const onMenuClick = (index) => {
    const question = appraisal?.questions[index];
    if (question) {
      let tempObj = {
        key: question.key,
        label: question.label,
        type: question.type,
        id: question.key,
        value: question.value,
        completed: question.completed,
      };
      setCurrentQuestion(tempObj);
      updateComment(question.comment);
      updateRating(question.rating);
      setCurrentQuestionIndex(index);
    }
  };

  const showSubmitConfirmModal = () => {
    setSubmitModal(true);
  };

  const onQuestionSave = () => {
    const submit = showSubmit && !currentQuestion.completed;
    Api.post(
      "/manager/submit-question/?associateId=" +
        selectedAssociateId +
        "&teamId=" +
        selectedTeamId
    )
      .params({
        currentQuestion,
        comment,
        rating,
        submit,
      })
      .send((response, error) => {
        setSubmitModal(false);
        if (response !== "undefined" && response?.show?.type === "success") {
          if (submit) {
            return history.goBack();
          }
          dispatch(globalActions.updateLoader({ loader: true }));
          Api.get(
            "/manager/appraisal-form?associateId=" +
              selectedAssociateId +
              "&teamId=" +
              selectedTeamId
          )
            .params({})
            .send((response, error) => {
              if (typeof response != "undefined") {
                dispatch(
                  managerActions.updateAppraisal({ appraisal: response })
                );
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                const question = appraisal?.questions[currentQuestionIndex + 1];
                if (question) {
                  let tempObj = {
                    key: question.key,
                    label: question.label,
                    type: question.type,
                    id: question.key,
                    value: question.value,
                    completed: question.completed,
                  };
                  setCurrentQuestion(tempObj);
                  updateComment(question.comment);
                  updateRating(question.rating);
                }
              }
            });
        }
      });
  };

  const questionsMenu = appraisal?.questions?.map((question, index) => {
    return (
      <Menu.Item onClick={() => onMenuClick(index)} key={index.toString()}>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {" "}
            {question.label}
          </div>
          {question.completed ? (
            <div>
              <CheckCircleFilled style={{ color: "green" }} />{" "}
            </div>
          ) : null}
        </div>
      </Menu.Item>
    );
  });

  // if (loader) {
  //   return null;
  // }

  return (
    <>
      <Title level={3} style={{ padding: "px",margin:'1.5rem',marginTop:"-1rem" }}>
        <span style={{color:"#0086FF"}}>{appraisal?.user?.name}</span> Feedback
      </Title>
      <Layout>
        <Sider>
          <Menu
            style={{ width: 256 }}
            defaultSelectedKeys={[null]}
            selectedKeys={[currentQuestionIndex?.toString()]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
          >
            {questionsMenu}
          </Menu>
        </Sider>
        <Layout>
          <Content>
            {currentQuestion ? (
              <Card style={{ margin: "100px" }}>
                <Form layout="vertical">
                  <FormInput
                    key={currentQuestion.key}
                    label={currentQuestion.label}
                    value={currentQuestion.value}
                    type={currentQuestion.type}
                    id={currentQuestion.key}
                    ratings={appraisal?.rating}
                    handleCommentChange={updateComment}
                    handleRatingChange={updateRating}
                    comment={comment}
                    rating={rating}
                  ></FormInput>
                  <Form.Item>
                    <Button
                      style={{ marginTop: 20 }}
                      type="primary"
                      htmlType="submit"
                      onClick={() => {
                        showSubmit && !currentQuestion.completed
                          ? showSubmitConfirmModal()
                          : onQuestionSave();
                      }}
                      disabled={isQuesDisabled()}
                    >
                      {showSubmit && !currentQuestion.completed
                        ? "Submit"
                        : "Save and Continue"}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Empty description="Select a question" />
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
      <Modal
        title="Submit Feedback"
        visible={isSubmitModal}
        onOk={onQuestionSave}
        onCancel={() => setSubmitModal(false)}
      >
        <p>Are you sure you want to submit the feedback ?</p>
      </Modal>
    </>
  );
};

export default ManagerAppraisalForm;
