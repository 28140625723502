import React, { useState, useEffect } from "react";
import { Button, Modal, Radio, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import downloadIcon from "../../assets/download .svg"

import userWhite from "../../assets/userWhite.svg"

import cycleWhite from "../../assets/cycleWhite.svg"
import { CloseOutlined } from '@ant-design/icons';


import Api from "services/Api";
import { adminActions } from "./store";

function CommonModal() {
  const dispatch = useDispatch();
  const [defaultValue, setDefaultValue] = useState("");
  const {
    designationModalShow,
    modalType,
    designationList,
    cycleList,
    defaultUserDesignation,
    defaultUserCycle,
    Id,
    page,
    pageSize,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    sorting,
    emailSort,
    searchText,
  } = useSelector((state) => state.admin);


  console.log("cycleeupdate", cycleList);

  const pageNumber =
    page !== "" || typeof page !== "undefined" ? `page=${page}` : "";
  const limit =
    pageSize !== "" || typeof pageSize !== "undefined"
      ? `&limit=${pageSize}`
      : "";
  const searchData =
    searchText === "" || typeof searchText === undefined
      ? ""
      : pageNumber === "" && limit === ""
      ? `search=${searchText}`
      : `&search=${searchText}`;

  const appraisalStatus =
    appraisalFilter === "" ||
    appraisalFilter === null ||
    appraisalFilter === undefined
      ? ""
      : `&appraisalStatus=${appraisalFilter}`;
  const desigFilter =
    designationFilter === "" ||
    designationFilter === null ||
    designationFilter === undefined
      ? ""
      : `&designation=${designationFilter}`;

  const cycFilter =
    cycleFilter === "" || cycleFilter === null || cycleFilter === undefined
      ? ""
      : `&appraisalCycle=${cycleFilter}`;
  const formstatusFilter =
    formStatusFilter === "" ||
    formStatusFilter === null ||
    formStatusFilter === undefined
      ? ""
      : `&status=${formStatusFilter}`;
  const sortingByName =
    sorting === "" || sorting === null || sorting === undefined
      ? ""
      : `&sort=${sorting}`;
  const sortingByEmail =
    emailSort === "" || emailSort === null || emailSort === undefined
      ? ""
      : `&emailsort=${emailSort}`;

  const downloadOptionsList = [
    "Complete Feedback Data",
    "Rank Data",
    "Work Efforts",
    "Associate Summary",
  ];

  useEffect(() => {
    modalType === "Designation"
      ? setDefaultValue(defaultUserDesignation)
      : setDefaultValue(defaultUserCycle);
    modalType === "Appraisal" ? setDefaultValue(defaultUserCycle) : "";
    // console.log("Default Value", modalType, Id);
  }, [defaultUserDesignation, defaultUserCycle, modalType, Id]);

  const updatePatch = () => {
    dispatch(adminActions.updateDesignationModal(false));
    if (modalType === "Designation" || modalType === "Appraisal") {
      Api.patch(`/admin/users/?userId=${Id}`)
        .params(
          modalType === "Designation"
            ? { associateLevel: defaultValue }
            : { appraisalCycle: defaultValue }
        )
        .send((response, error) => {
          // console.log("Patch Response", response);
          dispatch(
            adminActions.updateUser_Cycle_Designation({
              ...{},
              defaultCycle: "",
              defaultLevel: "",
            })
          );
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
        });
      return;
    }
    if (modalType === "AllUserDownload") {
      let phrase = "";
      if (defaultValue === "Complete Feedback Data") {
        Api.get(`/admin/downloadAllFeedback?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}`).send((response, error) => {
          if (response) {
            window.open(`${response}`);
          }
          dispatch(
            adminActions.updateUser_Cycle_Designation({
              ...{},
              defaultCycle: "",
              defaultLevel: "",
            })
          );
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
        });
      }
    }
    if (defaultValue === "Rank Data") {
      let value = "download=true";
      let phrase =
        value === "" || value === null || value === undefined
          ? ""
          : `&${value}`;
      Api.get(
        `/admin/users?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}${phrase}`
      ).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    if (defaultValue === "Work Efforts") {
      Api.get(`/admin/downloadAssociateSummary?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}`).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    if (defaultValue === "Associate Summary") {
      Api.get(`/admin/downloadAssociateSummary?${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}`).send((response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        dispatch(
          adminActions.updateUser_Cycle_Designation({
            ...{},
            defaultCycle: "",
            defaultLevel: "",
          })
        );
        dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
      });
    }
    setDefaultValue("");
  };

  const onCancel = () => {
    dispatch(adminActions.updateDesignationModal(false));
    dispatch(
      adminActions.updateUser_Cycle_Designation({
        ...{},
        defaultCycle: "",
        defaultLevel: "",
      })
    );
    setDefaultValue("");
  };

  const designationValue = () => (
    <Space style={{ marginTop: "0.5rem" }} direction="vertical">
      {Object.values(designationList).map((val, index) => (
        <Radio key={index} value={val}>
          {val}
        </Radio>
      ))}
    </Space>
  );

  const appraisalValue = () => (
    <Space style={{ marginTop: "1.5rem" }} direction="vertical">
      {Object.values(cycleList).map((val, index) => {
        return(<Radio key={index} value={val?.appraisalCycle}>
          {val?.appraisalCycle}
        </Radio>)
      })}
    </Space>
  );

  const downloadOptions = () => (
    <Space style={{ marginTop: "1rem" }} direction="vertical">
      {downloadOptionsList.map((val, index) => (
        <Radio key={index} value={val}>
          {val}
        </Radio>
      ))}
    </Space>
  );

  const valueOnChange = (e) => {
    if (modalType === "Designation" || modalType === "Appraisal") {
      setDefaultValue(e.target.value);
    }
    if (modalType === "AllUserDownload") {
      setDefaultValue(e.target.value);
    }
  };

  return (
    <Modal
      visible={designationModalShow}
      // title={<div style={{ fontFamily: "Roboto-Bold",backgroundColor:"blue",border:"2px solid red",width:"100%"}}><h3>Associate Level</h3></div>}
      bodyStyle={{padding:0, height:modalType === "AllUserDownload" ? "30vh" : "35vh"  }}
      onOk={updatePatch}
      
      
      footer={
        modalType === "AllUserDownload"
          ? [
              <Button
                onClick={updatePatch}
                type="primary"
                style={{ width: "8rem", height: "2.5rem" }}
                disabled={defaultValue === ""}
              >
                Download
              </Button>,
            ]
          : [
              <Button
                onClick={onCancel}
                style={{
                  width: "8rem",
                  height: "2.5rem",
                  marginRight: "1rem",
                }}
                size="large"
              >
                Cancel
              </Button>,
              <Button
                size="large"
                onClick={updatePatch}
                type="primary"
                style={{
                  width: "8rem",
                  height: "2.5rem",
                }}
              >
                OK
              </Button>,
            ]
      }
      onCancel={onCancel}
      closeIcon={<CloseOutlined style={{color:"white"}} />}
    >
      <div> 
        {modalType === "Designation" ? (
          <div  style={{ backgroundColor:"#0086FF",height:"3.5rem",display:"flex",justifyContent:"flex-start", padding:"1rem"}}>
            <img src={userWhite} style={{width:"2rem",height:"1.5rem"}} />
          <h3 style={{marginLeft:"0.5rem",color:"white",fontFamily: "Roboto-Bold"}} >
            
            Associate Level</h3>
          </div>
        ) : modalType === "Appraisal" ? (
          <div  style={{ backgroundColor:"#0086FF",height:"3.5rem",display:"flex",justifyContent:"flex-start", padding:"1rem"}}>
            <img src={cycleWhite} style={{width:"2rem",height:"1.5rem"}} />
          <h3 style={{marginLeft:"0.5rem",color:"white",fontFamily: "Roboto-Bold"}} >
            
            Appraisal cycle</h3>
          </div>
        ) : (
          <div  style={{ backgroundColor:"#0086FF",height:"3.5rem",display:"flex",justifyContent:"flex-start", padding:"1rem"}}>
            <img src={downloadIcon} style={{width:"1.2rem",height:"1.5rem"}} />
          <h3 style={{marginLeft:"0.5rem",color:"white",fontFamily: "Roboto-Bold"}} >
            
            Download User Data</h3>
          </div>
        )}
        {modalType === "Designation" ? (
          <div style={{ fontFamily: "Roboto-Italic",padding:"1rem",marginLeft:"1rem"}}>
            Change level of Associate
          </div>
        ) : modalType === "Appraisal" ? (
          <div style={{ fontFamily: "Roboto-Italic",padding:"1rem",marginLeft:"1rem" }}>
            Change the appraisal cycle for the Employee
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          height: modalType === "AllUserDownload" ? "15vh" : "20vh",
          display: "flex",
          alignItems: "flex-start",
          marginLeft:"2rem",
        }}
      >
        <Radio.Group value={defaultValue} onChange={valueOnChange} style={{marginTop:"0rem"}}>
          {modalType === "Designation"
            ? designationValue()
            : modalType === "Appraisal"
            ? appraisalValue()
            : downloadOptions()}
        </Radio.Group>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* {console.log("Selected option", defaultValue)} */}
      </div>
    </Modal>
  );
}

export default CommonModal;
