import { Button, Col, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { adminActions } from "./store";
import UserTable from "./user-table";

import "../styles/index.css";
import DownloadIcon from "../../assets/download .svg";
import SearchIcon from "../../assets/search icon.svg";
import ManualIcon from "../../assets/manual.png";
import ReloadIcon from "../../assets/ReloadIcon.svg";
import ReviseDataModal from "./reviseDataModal";
import DownArrowIcon from "../../assets/downArrowIcon.svg";

const Admin = () => {
  const [reviseModal, updateReviseModal] = useState(false);

  const {
    page,
    pageSize,
    refreshEffect,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    sorting,
    emailSort,
    searchText,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  // console.log(
  //   "eqqqqqqq",
  //   useSelector((state) => state.admin)
  // );
  // const [searchText, setSearchText] = useState("");

  const filtersList = [
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
  ];

  const pageNumber =
    page !== "" || typeof page !== "undefined" ? `page=${page}` : "";
  const limit =
    pageSize !== "" || typeof pageSize !== "undefined"
      ? `&limit=${pageSize}`
      : "";
  const searchData =
    searchText === "" || typeof searchText === undefined
      ? ""
      : pageNumber === "" && limit === ""
      ? `search=${searchText}`
      : `&search=${searchText}`;

  const appraisalStatus =
    appraisalFilter === "" ||
    appraisalFilter === null ||
    appraisalFilter === undefined
      ? ""
      : `&appraisalStatus=${appraisalFilter}`;
  const desigFilter =
    designationFilter === "" ||
    designationFilter === null ||
    designationFilter === undefined
      ? ""
      : `&designation=${designationFilter}`;

  const cycFilter =
    cycleFilter === "" || cycleFilter === null || cycleFilter === undefined
      ? ""
      : `&appraisalCycle=${cycleFilter}`;

  console.log("eeeee", cycFilter);
  const formstatusFilter =
    formStatusFilter === "" ||
    formStatusFilter === null ||
    formStatusFilter === undefined
      ? ""
      : `&status=${formStatusFilter}`;
  const sortingByName =
    sorting === "" || sorting === null || sorting === undefined
      ? ""
      : `&sort=${sorting}`;
  const sortingByEmail =
    emailSort === "" || emailSort === null || emailSort === undefined
      ? ""
      : `&emailsort=${emailSort}`;

  const getUserData = () => {
    Api.get(
      `/admin/users?${pageNumber}${limit}${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}`
    )
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          dispatch(adminActions.updateUsers({ users: response.users.items }));
          dispatch(adminActions.updateMeta(response.users.meta));
          dispatch(adminActions.updateCycle(response.cycle));
          dispatch(adminActions.updateDesignation(response.designation));
          dispatch(adminActions.updateStatus(response.status));
          dispatch(adminActions.updateTeams(response.teams));
          console.log("All users", response);
        }
      });
  };

  const dropDownData = () => {
    Api.get("/admin/getManagerDropdownData")
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("manager dropdown data", response);
          dispatch(adminActions.updateManagerDropDown(response));
        }
      });
    Api.get("/admin/getTeamDropdown")
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("team dropdown data", response);
          dispatch(adminActions.updateTeamDropDown(response));
        }
      });
    Api.get("/admin/getDepartmentDropdown")
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          // console.log("department dropdown data", response);
          dispatch(adminActions.updateDepDropDown(response));
        }
      });
  };
  useEffect(() => {
    // getUserData();
    dropDownData();
  }, []);

  // useEffect(()=>{
  //   handleSearch();
  // },[searchData])

  // console.log("searchText",searchText);

  const handleSearch = (e) => {
    setTimeout(() => {
      dispatch(adminActions.updateSearchText(e.target.value));
    }, 2000);
    Api.get(`/admin/users?search=${searchText}`)
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          dispatch(adminActions.updateUsers({ users: response.users.items }));
          dispatch(adminActions.updateMeta(response.users.meta));
          dispatch(adminActions.updateCycle(response.cycle));
          dispatch(adminActions.updateDesignation(response.designation));
          dispatch(adminActions.updateStatus(response.status));
          dispatch(adminActions.updateTeams(response.teams));
          // console.log("All users", response);
        }
      });
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "85%",
        marginLeft: "11rem",
        marginTop: "-0.8rem",
        // border:"1px solid blue"
      }}
    >
      <div
        style={{
          display: "flex",
          // border: "1px solid gray",
          // justifyContent: "space-between",
          // alignItems: "center",
          // border: "1px solid gray",
          // position:"relative",bottom:"0.5rem"
        }}
      >
        <div style={{ width: "15rem" }}>
          <h4
            style={{
              fontSize: "20px",
              fontFamily: "Roboto-Bold",
            }}
          >
            All Associates
          </h4>
        </div>
        <div
          style={{
            width: "25rem",
            marginRight: "25rem",
          }}
        >
          <Input
            style={{
              width: "18rem",
              height: "2.2rem",
            }}
            placeholder="Search"
            allowClear={true}
            onChange={(e) => {
              // console.log("Search Value", e.target.value);
              setTimeout(() => {
                // setSearchText(e.target.value);
                dispatch(adminActions.updateSearchText(e.target.value));
              }, 1000);
            }}
            prefix={
              <Button
                type="text"
                style={{ padding: "0", margin: "0" }}
                onClick={() => {
                  dispatch(
                    adminActions.updateRefreshEffect((olddata) => olddata + 1)
                  );
                  dispatch(adminActions.updatePage(e.target.value));
                }}
              >
                <img
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  src={SearchIcon}
                />
              </Button>
            }
            // onChange={handleSearch}
            // prefix={
            //   <Button
            //     type="text"
            //     style={{ padding: "0", margin: "0" }}
            //     // onClick={() => {
            //     //   dispatch(
            //     //     adminActions.updateRefreshEffect((olddata) => olddata + 1)
            //     //   );
            //     //   dispatch(adminActions.updatePage(e.target.value));
            //     // }}
            //   >
            //     <img
            //       style={{ width: "1.5rem", height: "1.5rem" }}
            //       src={SearchIcon}
            //     />
            //   </Button>
            // }
          />
        </div>

        {/* <div style={{ position: "relative", right: "5rem" }}>
          <Select
            defaultValue="All"
            suffixIcon={<img src={DownArrowIcon} />}
            style={{
              width: 158,
              color: "#0086FF",
              borderRadius: "5px",
              border: "1px solid #0086FF",
            }}
            bordered={false}
            onChange={handleAppraisalCycle}
            options={[
              {
                value: "",
                label: "All",
              },
              {
                value: "April",
                label: "Appraisal April",
              },
              {
                value: "October",
                label: "Appraisal October",
              },
            ]}
          />
        </div> */}

        <div
          style={{
            width: "12rem",
            position: "relative",
            right: "3.5rem",
          }}
        >
          <Button
            type="primary"
            style={{
              // display:"flex",
              backgroundColor: "#3BCF71",
              height: "2.1rem",
              border: "none",
              borderRadius: "4px",
              alignItems: "center",
              // border:"1px solid black"
            }}
            onClick={() => {
              dispatch(adminActions.updateDesignationModal(true));
              dispatch(adminActions.updateModalType("AllUserDownload"));
            }}
          >
            Download User Data
            <img
              style={{
                width: "1.25rem",
                height: "1.25rem",
                marginLeft: "0.8rem",
                marginBottom: "0.2rem",
              }}
              src={DownloadIcon}
              alt=""
            />
          </Button>
        </div>
        {/* <div style={{ position: "relative", right: "2.5rem" }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#FF6161",
              height: "2.1rem",
              border: "none",
              borderRadius: "4px",
              alignItems: "center",
            }}
            onClick={() => {
              updateReviseModal(true);
            }}
          >
            {" "}
            Revise Data
            <img
              src={ReloadIcon}
              style={{ height: "20px", width: "20px", marginLeft: "4px" }}
            />
          </Button>
        </div> */}

        <div
          style={{
            marginTop: "0.2rem",
            width: "15rem",
            position: "relative",
            right: "1.5rem",
          }}
        >
          <img
            style={{
              height: "1.5rem",
              width: "1.8rem",
            }}
            src={ManualIcon}
          />
          <text style={{ fontSize: "14px", fontWeight: "bold" }}>
            Manual Update
          </text>
        </div>
      </div>
      <UserTable />
      {reviseModal && (
        <ReviseDataModal
          reviseModal={reviseModal}
          updateReviseModal={updateReviseModal}
        />
      )}
    </div>
  );
};

export default Admin;
