import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { associateActions } from "./store";
import { Form, Input, Button, Select } from "antd";
import { Row, Col } from "antd";
import { AssociateStatus } from "./associate-status";
import LoadingModal from "components/Loader";
import { globalActions } from "store/loader";

const ManagerFeedbackForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get("/associate/manager-feedback-form/?userId=" + user.id)
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(associateActions.updateFeedback({ feedback: response }));
        }
      });
  }, []);

  const { feedback, associate } = useSelector((state) => state.associate);
  const [feedbackData, updateFeedbackData] = useState([]);
  const { loader } = useSelector((state) => state.global);
  useEffect(() => {
    if (!Object.keys(feedback).length) {
      return;
    }
    const responseToSend = [];
    feedback?.managers?.forEach((item) => {
      const feedbackArray = [];
      feedback?.managerFeedbackQuestions?.forEach((question, index) => {
        const feedBackObj = {
          factor_name: question.label,
        };
        if (question.type == "rating") {
          feedBackObj.rating = 0;
        } else {
          feedBackObj.input = "";
        }
        feedbackArray.push(feedBackObj);
      });
      const obj = {
        managerId: item.id,
        managerName: item.name,
        feedback: feedbackArray,
      };
      responseToSend.push(obj);
    });
    updateFeedbackData([...responseToSend]);
  }, [feedback]);

  const handleOnFinish = (values) => {
    Api.post("/associate/manager-feedback-form/?userId=" + user.id)
      .params({
        managerFeedback: [...feedbackData],
      })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          dispatch(
            associateActions.updateAssociate({
              associate: {
                ...associate,
                ...{ userStatus: AssociateStatus.managerFeedbackFilled },
              },
            })
          );
        }
      });
  };

  const managerFeedbackOptions = feedback?.managerRating?.map((item, index) => {
    return (
      <Select.Option key={index} value={item.value}>
        {item.label}
      </Select.Option>
    );
  });
  if (loader === true) {
    return null;
  }

  return (
    <Form
      name="basic"
      layout="vertical"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={(values) => handleOnFinish(values)}
      autoComplete="off"
    >
      <Row>
        {feedbackData.length
          ? feedbackData.map((item, i) => (
              <>
                <Col span={12}>
                  <div className="contained">
                    <hr className="solid"></hr>
                    <h2 style={{ color: "brown" }}>
                      Feedback for {item.managerName}
                    </h2>

                    {item.feedback.map((element, index) => {
                      const rules = [
                        {
                          required: true,
                          message: "All fields are mandatory",
                        },
                      ];
                      if (element.input !== undefined) {
                        rules.push({
                          min: 15,
                          message:
                            "Type minimum 15 characters",
                        });
                      }
                      return (
                        <Form.Item
                          key={`${i}_${index}`}
                          label={
                            <label style={{ color: "#1C5965" }}>
                              {element.factor_name}
                            </label>
                          }
                          name={`${i}_${index}`}
                          style={{ color: "red" }}
                          // managerId={item.id}
                          rules={rules}
                        >
                          {element.input !== undefined ? (
                            <Input
                              value={element.input}
                              placeholder="type minimum 15 characters"
                              onChange={(e) => (element.input = e.target.value)}
                            />
                          ) : (
                            <Select
                              placeholder="Select a rating"
                              onChange={(value) => (element.rating = value)}
                            >
                              {managerFeedbackOptions}
                            </Select>
                          )}
                        </Form.Item>
                        // {element.input !== undefined ? (

                        // ):(

                        // )

                        // }
                      );
                    })}
                  </div>
                </Col>
              </>
            ))
          : null}
      </Row>
      {/* {companyFeedbackQuestionList} */}
      <div className="Button">
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>{" "}
          {/* <Button htmlType="submit">Back</Button> */}
        </Form.Item>
      </div>
    </Form>
  );
};

export default ManagerFeedbackForm;
