import React from "react";
import Admin from "pages/admin/admin";
import Manager from "pages/manager/manager";
import AssociateHome from "pages/associate/associate-home";
import Login from "pages/login/Login";
import PrivateRoute from "components/PrivateRoute";
import { useSelector } from "react-redux";
import AssociateList from "pages/manager/associate-list";
import Api from "services/Api";
import ManagerAppraisalForm from "pages/manager/manager-appraisal-form";
import AssociateRank from "pages/manager/associate-rank";
import PerformanceMatrix from "pages/manager/performance-matrix";
import LoadingModal from "components/Loader";
import AssociateDetails from "pages/admin/associateDetails";
import ManagerFeedbackForm from "pages/associate/manager-feedback-form";
import Team from "pages/admin/team";
import AdminRoutes from "pages/admin/Routers/AdminRoutes";

function RoutesPrivate() {
  return (
    <div>
      <PrivateRoute path="/associate" role="Associate">
        <AssociateHome />
      </PrivateRoute>
      <PrivateRoute path="/manager" role="Manager" exact>
        <Manager />
      </PrivateRoute>
      <PrivateRoute path="/manager/associate-list" role="Manager" exact>
        <AssociateList />
      </PrivateRoute>
      <PrivateRoute path="/manager/associate-rank" exact role="Manager">
        <AssociateRank />
      </PrivateRoute>
      <PrivateRoute path="/manager/appraisal-form" exact role="Manager">
        <ManagerAppraisalForm />
      </PrivateRoute>
      <PrivateRoute path="/manager/performance-matrix" exact role="Manager">
        <PerformanceMatrix />
      </PrivateRoute>
      <PrivateRoute path="/admin" role="Admin">
        <Admin />
      </PrivateRoute>
      <PrivateRoute path="/associatesDetails" role="Admin">
        <AssociateDetails />
      </PrivateRoute>
    </div>
  );
}

export default RoutesPrivate;
