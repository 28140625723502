import React, { useState, useEffect } from "react";
import { Button, Col, Collapse, Popover, Row, Switch, Table } from "antd";

import classes from "../styles/associateDetails.module.css";
import { Color } from "services/color";
import "../styles/index.css";


const FeedbackTable = (props) => {
  const { Panel } = Collapse;

    console.log("Self Feedback", props);
    const columns = [
      {
        title: (
          <h5 style={{ fontSize: "1rem", fontFamily: "Roboto-Bold" }}>
            Questions
          </h5>
        ),
        dataIndex: "",
        key: "",
        width: "40%",
        render: (val, row) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h5 style={{ fontSize: "1rem", fontFamily: "Roboto-BoldItalic" }}>
              {row?.questionKey}
            </h5>
            <h5 style={{ fontSize: "1rem", fontFamily: "Roboto-Regular" }}>
              {row?.value}
            </h5>
          </div>
        ),
      },
      {
        title: (
          <h5 style={{ fontSize: "1rem", fontFamily: "Roboto-Bold" }}>
            Rating
          </h5>
        ),
        dataIndex: "rating",
        key: "rating",
        width: "20%",
        render: (val, row) => (
          <div style={{ display: "flex" }}>
            <span style={{ color: Color.ratingColor, fontSize: "1rem" }}>
              {row?.rating ? row?.rating : "NA"}
            </span>
          </div>
        ),
      },
      {
        title: (
          <h5 style={{ fontSize: "1rem", fontFamily: "Roboto-Bold" }}>
            Remarks for Associate
          </h5>
        ),
        dataIndex: "remark",
        key: "remark",
        width: "",
        render: (val, row) =>
          row?.remark === null ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span style={{ color: Color.ratingColor }}>-</span>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <span style={{ color: Color.ratingColor, fontSize: "1rem" }}>
                {row?.remark}
              </span>
            </div>
          ),
      },
    ];
    return (
      <div className={classes.associatesFeedback}>
        <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
          <Panel
            header={
              <h3
                style={{
                  marginTop: "0.5rem",
                  fontSize: "1.188rem",
                  fontFamily: "Roboto-Bold",
                }}
              >
                {props?.sectionData.title}
              </h3>
            }
            key="1"
            style={{ padding: "0" }}
            className="customPanel"
          >
            <Table
              columns={columns}
              pagination={{
                position: ["none"],
                pageSize: props?.sectionData?.data?.length,
              }}
              className={classes.feedbackThemselvesTable}
              dataSource={props?.sectionData?.data}
              style={{
                width: "100%",
                boxShadow: "0px 3px 6px #0000000F",
              }}
              scroll={{
                y: "45vh",
              }}
            />
          </Panel>
        </Collapse>
      </div>
    );
  };

export default FeedbackTable  