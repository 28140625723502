import React, { useEffect, useState } from "react";
import { Button, Table, Col, Input, Row, Popover } from "antd";
import DownloadIcon from "../../assets/download .svg";
import SearchIcon from "../../assets/search icon.svg";
import { PlusCircleTwoTone } from "@ant-design/icons";
import AppraisalCycleModal from "./appraisalCycle-modal";
import { AiFillFilter, AiFillCaretUp, AiFillCaretDown } from "services/Icons";
import Api from "services/Api";
import "./tableHeader.css";
import pluseIcon from "../../assets/pluse-circle.svg";
import EditIcon from "../../assets/editIcon.svg";
import { render } from "@testing-library/react";

function AppraisalCycle() {
  const [showEditModal, updateEditModal] = useState(false);
  const [showAddModal, updateAddModal] = useState(false);
  const [details, updateDetails] = useState();
  const [cycleData, setCycleData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [downloadCycle, setDownloadCycle] = useState("downloadCycle");

  const getTableData = () => {
    setLoader(true);
    Api.get(`/admin/cycle`)
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setCycleData(response);
          console.log("Cycle List", response);
          setLoader(false);
        }
      });
  };

  console.log("cyccllee", cycleData);

  useEffect(() => {
    getTableData();
  }, []);

  const handleSearchInput = (e) => {
    console.log("search text", e.target.value);
    setSearchQuery(e.target.value);
    Api.get(`/admin/cycle?search=${e.target.value}`).send((response, error) => {
      if (typeof response !== "undefined") {
        setLoader(false);
        setCycleData(response);
        // console.log("Team Search users", response);
      }
    });
  };

  const handleSearchTeam = () => {
    setLoader(true);
    Api.get(`/admin/cycle?search=${searchQuery}`).send((response, error) => {
      if (typeof response !== "undefined") {
        setLoader(false);
        setCycleData(response);
        console.log("Team Search users", response);
      }
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchTeam();
    }
  };

  const handleDownload = () => {
    setLoader(true);

    Api.get(`/admin/cycle?downloadCycle=${downloadCycle}&search=${searchQuery}`)
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          const url = response;
          const link = document.createElement("a");
          link.href = url;
          link.download = "allData.csv"; // set the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          console.log("Download users", response);
          setLoader(false);
        }
      });
  };

  const sortAscending = <h4>Click to sort ascending </h4>;
  const sortDescending = <h4>Click to sort descending </h4>;

  const handleSortByCycle = (value) => {
    console.log("Clicked", value);
    setLoader(true);
    Api.get(`/admin/cycle`, {
      sort: value,
      sortBy: "appraisalCycle",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      });
  };
  const handleSortByStartDate = (value) => {
    console.log("Clicked", value);
    setLoader(true);
    Api.get(`/admin/cycle`, {
      sort: value,
      sortBy: "startDate",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          // console.log("cyclleeee",response);
          setCycleData(response);
        }
      });
  };
  const handleSortByEndDate = (value) => {
    console.log("Clicked", value);
    setLoader(true);
    Api.get(`/admin/cycle`, {
      sort: value,
      sortBy: "endDate",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      });
  };
  const handleSortByDuration = (value) => {
    console.log("Clicked Duration", value);
    setLoader(true);
    Api.get(`/admin/cycle`, {
      sort: value,
      sortBy: "duration",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setCycleData(response);
        }
      });
  };

  const columns = [
    {
      title: (
        <div style={{ marginLeft: "0.3rem",fontWeight:"bold" }}>
          Appraisal Cycle
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "0px",
              width: "10rem",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Popover content={sortAscending}>
              <Button
                type="text"
                style={{
                  height: "0.6rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "7rem",
                  bottom: "2px",
                  color: "#787b83",
                }}
                onClick={() => handleSortByCycle("ASC")}
              >
                <AiFillCaretUp />
              </Button>
            </Popover>
            <Popover content={sortDescending}>
              <Button
                type="text"
                style={{
                  height: "0.8rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "7rem",
                  color: "#787b83",
                }}
                onClick={() => handleSortByCycle("DESC")}
              >
                {<AiFillCaretDown />}
              </Button>
            </Popover>
          </div>
        </div>
      ),
      dataIndex: "cycle_appraisalCycle",
      key: "1",
      width: "12rem",
      render: (val, row) => {
        // console.log("cycleeee roww", row);
        return <>{row?.cycle_appraisalCycle}</>;
      },
    },
    {
      title: (
        <div style={{ marginLeft: "0.3rem",fontWeight:"bold" }}>
          Start Date
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "0px",
              width: "10rem",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Popover content={sortAscending}>
              <Button
                type="text"
                style={{
                  height: "0.6rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "5rem",
                  bottom: "2px",
                  color: "#787b83",
                }}
                onClick={() => handleSortByStartDate("ASC")}
              >
                <AiFillCaretUp />
              </Button>
            </Popover>
            <Popover content={sortDescending}>
              <Button
                type="text"
                style={{
                  height: "0.8rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "5rem",
                  color: "#787b83",
                }}
                onClick={() => handleSortByStartDate("DESC")}
              >
                {<AiFillCaretDown />}
              </Button>
            </Popover>
          </div>
        </div>
      ),
      dataIndex: "cycle_startDate",
      key: "2",
      width: "12rem",
      render: (val, row) => {
        // console.log("cycleeee roww",row);
        return <>{row?.cycle_startDate}</>;
      },
    },
    {
      title: (
        <div style={{ marginLeft: "0.3rem" ,fontWeight:"bold"}}>
          End Date
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "0px",
              width: "10rem",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Popover content={sortAscending}>
              <Button
                type="text"
                style={{
                  height: "0.6rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "5rem",
                  bottom: "2px",
                  color: "#787b83",
                }}
                onClick={() => handleSortByEndDate("ASC")}
              >
                <AiFillCaretUp />
              </Button>
            </Popover>
            <Popover content={sortDescending}>
              <Button
                type="text"
                style={{
                  height: "0.8rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "5rem",
                  color: "#787b83",
                }}
                onClick={() => handleSortByEndDate("DESC")}
              >
                {<AiFillCaretDown />}
              </Button>
            </Popover>
          </div>
        </div>
      ),
      dataIndex: "cycle_endDate",
      key: "3",
      width: "12rem",
      render: (val, row) => {
        // console.log("cycleeee roww",row);
        return <>{row?.cycle_endDate}</>;
      },
    },
    {
      title: (
        <div style={{ marginLeft: "0.3rem",fontWeight:"bold" }}>
          Duration
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "0px",
              width: "10rem",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Popover content={sortAscending}>
              <Button
                type="text"
                style={{
                  height: "0.6rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "4rem",
                  bottom: "2px",
                  color: "#787b83",
                }}
                onClick={() => handleSortByDuration("ASC")}
              >
                <AiFillCaretUp />
              </Button>
            </Popover>
            <Popover content={sortDescending}>
              <Button
                type="text"
                style={{
                  height: "0.8rem",
                  margin: "0",
                  padding: "0",
                  width: "20px",
                  marginLeft: "4rem",
                  color: "#787b83",
                }}
                onClick={() => handleSortByDuration("DESC")}
              >
                {<AiFillCaretDown />}
              </Button>
            </Popover>
          </div>
        </div>
      ),
      dataIndex: "cycle_duration",
      key: "4",
      width: "12rem",
      render: (val, row) => {
        // console.log("cycleeee roww",row);
        return <>{row?.cycle_duration}</>;
      },
    },
    {
      title: <div style={{ marginLeft: "0.3rem",fontWeight:"bold" }}>Action</div>,
      dataIndex: "edit",
      key: "5",
      width: "12rem",
      render: (val, row) => {
        // console.log("cyclerowdata",row)
        return (  
          <Button
            style={{
              backgroundColor: "#FAFAFA",

              border: "none",
            }}
            shape="circle"
            onClick={() => {
              updateEditModal(true);
              updateDetails(row);
            }}
            disabled={true}
          >
            <img src={EditIcon} />
          </Button>
        );
      },
    },
  ];

  return (
    <div style={{ position: "fixed", marginLeft: "12rem",  marginTop: "-0.8rem", }}>
      <div>
        <Row
          // className="row"
          style={{
            display: "flex",
          }}
        >
          <div>
            <h4
              style={{
                fontSize: "1.5rem",
                fontFamily: "Roboto-Bold",
              }}
            >
              Appraisal Cycle
            </h4>
          </div>
          <Input
            style={{
              width: "18rem",
              height: "2.2rem",
              marginLeft: "0.5rem",
            }}
            placeholder="Search"
            allowClear={true}
            onChange={handleSearchInput}
            onKeyPress={handleKeyPress}
            prefix={
              <Button
                type="text"
                onClick={handleSearchTeam}
                style={{ padding: "0", margin: "0" }}
              >
                <img
                  style={{ width: "1.3rem", height: "1.3rem" }}
                  src={SearchIcon}
                />
              </Button>
            }
          />
          <div>
            <div
              style={{
                display: "flex",
                marginLeft: "20rem",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#0086FF",
                  border: "none",
                  borderRadius: "4px",
                  color: "white",
                  width: "7rem",
                  fontSize: "14px",
                  
                }}
                onClick={() => {
                  updateAddModal(true);
                }}
                disabled={true}
              >
                <img
                  src={pluseIcon}
                  style={{
                    width: "0.9rem",
                    // height: "3rem",
                    marginRight: "0.3rem",
                    marginBottom: "0.1rem",
                  }}
                />
                Add Cycle
              </Button>
            </div>
          </div>
          <div>
            <div
              style={{
                marginLeft: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  fontSize: "14px",
                  color: "white",
                  backgroundColor: "#3BCF71",
                  borderRadius: "4px",
                  border: "none",
                }}
                onClick={handleDownload}
              >
                Download Cycle Data
                <img
                  style={{
                    width: "1.2rem",
                    height: "1.2rem",
                    marginLeft: "0.5rem",
                    alignItems: "center",
                  }}
                  src={DownloadIcon}
                  alt=""
                />
              </Button>
            </div>
          </div>
        </Row>
      </div>
      <div style={{ width: "82%", position: "fixed" }}>
        <Table
          dataSource={cycleData}
          columns={columns}
          // loading={loader}
          pagination={{
            size: "small",
            total: cycleData.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            defaultPageSize: 10,
            defaultCurrent: 1,
            // position:['bottomLeft'],
          }}
          className="test"
          scroll={{
            y: "55vh",
          }}
        />
      </div>
      {showAddModal && (
        <AppraisalCycleModal
          showAddModal={showAddModal}
          refresh={getTableData.bind(this)}
          updateAddModal={updateAddModal}
        />
      )}
      {showEditModal && (
        <AppraisalCycleModal
          refresh={getTableData.bind(this)}
          showEditModal={showEditModal}
          updateEditModal={updateEditModal}
          data={details}
        />
      )}
    </div>
  );
}

export default AppraisalCycle;
