import { Layout, Menu } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { adminActions } from "./store";
import { useHistory, NavLink } from "react-router-dom";

import "../styles/index.css";
import brigosha_logo from "assets/brigosha_logo.svg";
import AdminHeader from "./AdminHeader";
import AdminRoutes from "./Routers/AdminRoutes";

import UserIcon from "../../assets/userIcon.svg";
import TeamIcon from "../../assets/teamIcon.svg";
import LinkIcon from "../../assets/cycle.svg";

import userBlack from "../../assets/user.svg";
import teamColor from "../../assets/teamColor.svg";
import linkColor from "../../assets/cycleColor.svg";

const Admin = () => {
  const {
    page,
    pageSize,
    refreshEffect,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    sorting,
    emailSort,
    searchText,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { Header, Content, Sider } = Layout;
  const history = useHistory();

  const selectedMenu = (e) => {
    const path = e.key
    // console.log(":selected option", e.key);
    // console.log("selected ", e);
    history.push(`${e.key}`);
  };
  const items = [
    {
      label: "Appraisal Cycle",
      // key: "appraisalLink",
      key: "/admin/appraisalLink",

      active: linkColor,
      inActive: LinkIcon,
    },
    {
      label: "Team",
      // key: "team",
      key: "/admin/team",

      active: teamColor,
      inActive: TeamIcon,
    },
    {
      label: "Users",
      // key: "users",
      key: "/admin",

      active: UserIcon,
      inActive: userBlack,
    },
  ];

  // const [searchText, setSearchText] = useState("");
  const pageNumber =
    page !== "" || typeof page !== "undefined" ? `page=${page}` : "";
  const limit =
    pageSize !== "" || typeof pageSize !== "undefined"
      ? `&limit=${pageSize}`
      : "";
  const searchData =
    searchText === "" || typeof searchText === undefined
      ? ""
      :`&search=${searchText}`;

  const appraisalStatus =
    appraisalFilter === "" ||
    appraisalFilter === null ||
    appraisalFilter === undefined
      ? ""
      : `&appraisalStatus=${appraisalFilter}`;
  const desigFilter =
    designationFilter === "" ||
    designationFilter === null ||
    designationFilter === undefined
      ? ""
      : `&designation=${designationFilter}`;

  const cycFilter =
    cycleFilter === "" || cycleFilter === null || cycleFilter === undefined
      ? ""
      : `&appraisalCycle=${cycleFilter}`;
  const formstatusFilter =
    formStatusFilter === "" ||
    formStatusFilter === null ||
    formStatusFilter === undefined
      ? ""
      : `&status=${formStatusFilter}`;
  const sortingByName =
    sorting === "" || sorting === null || sorting === undefined
      ? ""
      : `&sort=${sorting}`;
  const sortingByEmail =
    emailSort === "" || emailSort === null || emailSort === undefined
      ? ""
      : `&emailsort=${emailSort}`;

  const searchApi=()=>{
    Api.get(
      `/admin/users?${searchData}`
    )
      .params({})
      .send((response) => {
        if (typeof response !== "undefined") {
          dispatch(adminActions.updateUsers({ users: response.users.items }));
          dispatch(adminActions.updateMeta(response.users.meta));
          dispatch(adminActions.updateCycle(response.cycle));
          dispatch(adminActions.updateDesignation(response.designation));
          dispatch(adminActions.updateStatus(response.status));
          dispatch(adminActions.updateTeams(response.teams));
          // console.log("All users", response);
        }
      });
  }

  useEffect(() => {

    {searchData?(
      searchApi()
    ):(
      Api.get(
        `/admin/users?${pageNumber}${limit}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}`
      )
        .params({})
        .send((response) => {
          if (typeof response !== "undefined") {
            dispatch(adminActions.updateUsers({ users: response.users.items }));
            dispatch(adminActions.updateMeta(response.users.meta));
            dispatch(adminActions.updateCycle(response.cycle));
            dispatch(adminActions.updateDesignation(response.designation));
            dispatch(adminActions.updateStatus(response.status));
            dispatch(adminActions.updateTeams(response.teams));
            // console.log("All users", response);
          }
        })
    )}
   
  }, [refreshEffect,searchText]);

  return (
    <Layout>
      <Sider
        width={180}
        style={{
          background: "#fff",
          borderRight: "1px solid #E3F2FF",
          height: "100vh",

          position: "fixed",
          zIndex: 2,
        }}
      >
        <div
          style={{
            height: "10vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={brigosha_logo} alt="" />
        </div>

        <Menu
          mode="inline"
          defaultSelectedKeys={["users"]}
          style={{
            height: "100%",
            // borderTop: "2rem solid white",
          }}
          selectedKeys={window.location.pathname}
          onClick={selectedMenu}
        >
          {items.map((data) => (
            <Menu.Item key={data.key}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <img
                    src={
                      data.key === window.location.pathname
                        ? data.active
                        : data.inActive
                    }
                    alt=""
                    style={{
                      height: "1.5rem",
                      width: "1.5rem",
                      margin: "0.5rem",
                    }}
                  />
                </div>
                <div style={{fontWeight:"bold"}}>{data.label}</div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout
        style={{
          background: "#fff",
        }}
      >
        <Layout
          style={{
            background: "#fff",
            maxHeight: "50vh",
          }}
        >
          <Header
            style={{
              padding: "0",
            }}
          >
            <AdminHeader />
          </Header>
        </Layout>
        <Layout style={{ marginTop: "3rem", height: "87.5vh" }}>
          <Content style={{ background: "#fff", padding: "0 1rem" }}>
            <AdminRoutes />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Admin;
