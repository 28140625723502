import React from "react";
import classes from "./Header.module.css";
import { size } from "lodash";
import { Button, Dropdown, Menu, Row, Select, Space, Layout } from "antd";
import { AntDesignOutlined } from "@ant-design/icons";

import { Color } from "services/color";
import brigosha_logo from "assets/brigosha_logo.svg";
import faq_icon from "assets/faq_icon.svg";
import log_out_icon from "assets/log_out_icon.svg";
import logo_icon from "../../assets/Logo_Icon.svg";
import { authActions } from "pages/login/store";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDown } from "react-icons/ai";
import "./index.css";
import { useHistory } from "react-router-dom";
import Manager_FAQ from "../../assets/Manager_FAQ.pdf";
import Associate_FAQ from "../../assets/Associate_FAQ.pdf";
import { useState } from "react";
import { adminActions } from "pages/admin/store";
import DownArrowIcon from "../../assets/downArrowIcon.svg";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ProfileIcon from "../../assets/profileIcon.svg";
import logoutIcon from "../../assets/noun-logout-5695200 (1).svg"

const HeaderLayout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Sider, Header, Content } = Layout;
  // const {Option} = Select
  // console.log("history", history, useHistory());
  const { token, user } = useSelector((state) => state.auth);

  const roles = ["Admin", "Manager"];
  const [view, setView] = useState(user?.role)
  

  
  
  const logoutHandler = () => {
    dispatch(authActions.removeUser());
  };
  
  const onChangeRole = (userRole) => {
    // console.log("User state changed into",{...user, role: userRole})
    dispatch(authActions.changerRole({ ...user, role: userRole }));
    // userRole === "Manager" ? <Redirect to={"/manager"} /> :<Redirect to={"/admin"}/>;
    if (userRole === "Manager") {
      <Redirect to={"/manager"} />
    } else if (userRole === "Admin") {
      <Redirect to={"/admin"} />
    } else {
      <Redirect to={"/associate"} />
    }
    window.location.reload(false);  
    // window.location.reload(true);
  };

  const handleView = (value) => {
    console.log(`selected ${value}`);
    onChangeRole(value)
    
  };


  const year = new Date().getFullYear();


  const onDowloadFAQ = () => {
    // console.log("Clicking on Download", user?.role);
    if (user?.role === "Admin") {
      // console.log("Download Admin FAQ");
      return;
    }
    if (user?.role === "Manager") {
      window.open(Manager_FAQ);
      // console.log("Download Manager FAQ");
      return;
    }
    if (user?.role === "Associate") {
      window.open(Associate_FAQ);
      // console.log("Download Associate FAQ");
      return;
    }
  };

  return (
    user?.role !== "Admin" && (
      <div>
        <div
          style={{ boxShadow: `0px 4px 5px ${Color.midGrey}` }}
          className={classes.header}
        >
          {user?.role !== "Admin" && <img src={brigosha_logo} alt="" />}
          {user?.id === 67 || user?.id === 426 || user?.id === 480 ?(
        <div style={{ position: "relative", right: "5rem" }}>
        <Select
            defaultValue={user?.role}
            suffixIcon={<img src={DownArrowIcon}  style={{width:"1rem",height:"0.6rem",position:"relative",bottom:"6px"}}/>}
            style={{
              width: 158,
              color: "#0086FF",
              borderRadius: "5px",
              border: "1px solid #0086FF",
            }}
            bordered={false}
            onChange={handleView}
            options={[
              
              {
                value: "Manager",
                label: "Manager",
              },
              {
                value: "Admin",
                label: "Admin",
              },
              {
                value: "Associate",
                label: "Associate",
              },
            ]}
          />
        </div>
        ):(null)}
          {token && (
            <div style={{ marginRight: "3rem", display: "flex" }}>
              {user?.role !== "Admin" && (
                <Space>
                  <Button
                    style={{
                      width: "10rem",
                      height: "1.9rem",
                      border: `1px solid ${Color.buttonColor}`,
                      background: Color.buttonColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      borderRadius: "3px",
                    }}
                    onClick={onDowloadFAQ}
                  >
                    <img
                      style={{
                        width: "1rem",
                        height: "1rem",
                        margin: "0",
                        padding: "0",
                      }}
                      src={faq_icon}
                      alt=""
                    />
                    <h5
                      style={{
                        color: Color.white,
                        margin: "0.5rem",
                        fontFamily: "OpenSans-Regular",
                        fontSize: "0.875rem",
                      }}
                    >
                      Download FAQ's
                    </h5>
                  </Button>
                </Space>
              )}

              <div style={{ marginLeft: "1rem" }} className="logout">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item style={{ background: "none", padding: "0" }}>
                        <Button
                          ghost
                          style={{
                            width: "100%",
                            margin: "0",
                          }}
                          type="text"
                          onClick={logoutHandler}
                        >
                          <span
                            style={{
                              fontFamily: "Roboto-Bold",
                            }}
                          >
                            Logout
                          </span>
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <div
                    style={{
                      width: "10rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "2rem",
                        height: "2rem",
                        margin: "0",
                        padding: "0",
                      }}
                      src={ProfileIcon}
                      alt=""
                    />
                    <h6
                      style={{
                        margin: "0 0 0 0.5rem ",
                        fontFamily: "Roboto-Bold",
                        fontSize: "1rem",
                      }}
                    >
                      {user?.name}
                    </h6>
                    <img 
                      src={logoutIcon}
                      style={{
                        height:"1.3rem",
                        width:"2rem",
                        margin: "0 0.5rem",
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            width: "97%",
            margin: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2
            style={{
              fontSize: "1.9rem",
              fontFamily: "Roboto-Bold",
            }}
          >
            Appraisal
          </h2>
          {user?.id === 67|| user?.id === 426 || user?.id === 480 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {/* <Select
                // options={roles}
                dropdownMatchSelectWidth={false}
                defaultValue={user?.role === "Admin" ? "Admin" : "Manager"}
                onChange={(value) => {
                  onChangeRole(value);
                }}
              >
                {roles.map((item, index) => (
                  <Select.Option key={index} label={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select> */}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default HeaderLayout;
