import React, { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "antd/dist/antd.css"
import Header from "components/Layout/Header";
import Admin from "pages/admin/admin";
import Manager from "pages/manager/manager";
import AssociateHome from "pages/associate/associate-home";
import Login from "pages/login/Login";
import PrivateRoute from "components/PrivateRoute";
import { useSelector } from "react-redux";
import AssociateList from "pages/manager/associate-list";
import Api from "services/Api";
import ManagerAppraisalForm from "pages/manager/manager-appraisal-form";
import AssociateRank from "pages/manager/associate-rank";
import PerformanceMatrix from "pages/manager/performance-matrix";
import LoadingModal from "components/Loader";
import AssociateDetails from "pages/admin/associateDetails";
import ManagerFeedbackForm from "pages/associate/manager-feedback-form";
import RoutesPrivate from "./RoutesPrivate";

function Routes() {
  const defaultRoute = {
    Associate: "/associate",
    Manager: "/manager",
    Admin: "/admin",
  };
  const { user, token } = useSelector((state) => state.auth);
  const { loader } = useSelector((state) => state.global);
  // console.log("coming to routes", user?.role);
  useEffect(() => {
    if (token) {
      Api.header(token);
    }
    if (user?.id === 67){
      console.log("Shakira logged in")
    }
  }, [token]);

  return (
    <main>
      <Router>
        <Switch>
          {user?.id !== 67 || user?.id !== 426 || user?.id !== 480 ? (
            <>
              <Route path="/login" exact>
                <Login />
              </Route>
              <Route
                exact
                path="/"
                render={() => {
                  return token ? (
                    <Redirect to={defaultRoute[user?.role]} />
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />
              <RoutesPrivate />
              {/* <Route path="*">404</Route> */}
            </>
          ) : (
            <>
              <Route
                exact
                path="/"
                render={() => {
                  return token ? (
                    <Redirect to={defaultRoute[user?.role]} />
                  ) : (
                    // <Redirect to="/login" />
                    ""
                  );
                }}
              />
              <RoutesPrivate />
            </>
          )}
        </Switch>
      </Router>
      <LoadingModal visible={loader} />
    </main>
  );
}

export default Routes;
