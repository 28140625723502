const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    loader: false
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        updateLoader(state, action) {
            state.loader = action.payload.loader;
        }
    }
});

export default globalSlice.reducer;

export const globalActions = globalSlice.actions;