import { createSlice } from "@reduxjs/toolkit";
import { clearFromLocalStorage } from "services/Storage";

const initialState = {
  token: null,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateToken(state, action) {
      state.token = action.payload.token;
    },
    updateUser(state, action) {
      state.user = { ...action.payload.user }; 
    },
    removeUser(state, action) {
      state.user = {};
      state.token = null;
      clearFromLocalStorage();
    },
    changerRole(state,action){
      // console.log()
      console.log("User Role",action)
      state.user ={...action.payload}
    }
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;
