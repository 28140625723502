import React, { useState,useEffect } from "react";
import { Button, Input, DatePicker, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import Api from "services/Api";

import cycleWhite from "../../assets/cycleWhite.svg";
import { CloseOutlined } from "@ant-design/icons";

function AppraisalCycleModal(props) {
  const [cycle, setCycle] = useState();
  const [disabled, setDisabled] = useState(true);
  const [editButton, setSaveButton] = useState(false);
  const [startAddDate, setStartAddDate] = useState();
  const [endAddDate, setEndAddDate] = useState();

  const [startEditDate, setStartEditDate] = useState();
  const [endEditDate, setEndEditDate] = useState();
  const [blockButton , setBlockButton] = useState(true);

  const data = props?.data;
  console.log("dats", data);
  const sdate = data?.cycle_startDate;
  const edate = data?.cycle_endDate;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateFormat = "DD/MM/YYYY";

  //hanlde Edit button

  const handleEditOk = () => {
    Api.patch(`/admin/updateCycle`, {
      id: data?.cycle_id,
      appraisalCycle: cycle,
      startDate: startEditDate || sdate,
      endDate: endEditDate || edate,
    }).send((response, error) => {
      if (typeof response !== "undefined") {
        console.log("cycle List", response);
        props.refresh();
      }
    });
    props?.updateEditModal(false);
  };

  const handleEditSelectChange = (value) => {
    setCycle(value);
    // console.log(`selected ${value}`);
  };

  const onEditStartChange = (date, dateString) => {
    // console.log("start date", date, dateString);
    setStartEditDate(dateString);
  };
  const onEditEndChange = (date, dateString) => {
    // console.log("End Date", date, dateString);
    setEndEditDate(dateString);
  };

  //  handle adds Button
  const handleAddOk = () => {
    props?.updateAddModal(false);
  };
  const onStartChange = (date, dateString) => {
    console.log("start date", dateString);
    setStartAddDate(dateString);
  };
  const onEndChange = (date, dateString) => {
    console.log("End Date", dateString);
    setEndAddDate(dateString);
  };
  const handleAddSelectChange = (value) => {
    setCycle(value);
    console.log(`selected ${value}`);
  };

  const handleAddCycle = () => {
    console.log("hanlde clicking");
   
    Api.post(`/admin/appraisalCycle`).params({
      appraisalCycle: cycle,
      startDate: startAddDate,
      endDate: endAddDate,
    }).send((response, error) => {
      if (typeof response !== "undefined") {
        console.log("cycle List", response);
        props.refresh();
      }
    });
    props?.updateAddModal(false);
  };

  // console.log("startenddate", startAddDate, endAddDate);
  // console.log("startenddate 2222", startEditDate, endEditDate);

  const handleAddCancelCycle = () => {
    props?.updateAddModal(false);
  };

  const handleEdit = () => {
    setDisabled(false);
    setSaveButton(true);
  };

  // console.log("seeee", sdate, edate);
  useEffect(() => {
    if (cycle && startAddDate && endAddDate) {
      setBlockButton(false);
    } else {
      setBlockButton(true);
    }
  }, [cycle, startAddDate, endAddDate]);

  return (
    <div>
       
      <Modal // ADD cycle modal
        
        open={props?.showAddModal}
        onCancel={() => {
          props?.updateAddModal(false);
        }}
        onOk={handleAddOk}
        footer={null}
        width={600}
        bodyStyle={{ padding: 0, height: "400px" }}
        closeIcon={<CloseOutlined style={{color:"white"}} />}
      >
        <div
          style={{
            backgroundColor: "#0086FF",
            height: "3.5rem",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
        >
          <img src={cycleWhite} style={{ width: "2rem", height: "1.5rem" }} />
          <h3
            style={{
              marginLeft: "0.5rem",
              color: "white",
              fontFamily: "Roboto-Bold",
            }}
          >
            Appraisal Cycle
          </h3>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Roboto-Italic",
              fontSize: "1rem",
              padding: "1rem",
            }}
          >
            Summary of Appraisal Cycle.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label>
            Appraisal Cycle*
            <div>
              <Select
                placeholder="Choose Appraisal Cycle Month"
                style={{
                  width: "30rem",
                }}
                onChange={handleAddSelectChange}
              >
                {months.map((id) => {
                  return (
                    <Select.Option key={id} value={id}>
                      {id}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <div style={{ width: "16rem", marginLeft: "2rem" }}>
            <label>
              Start Date*
              <div>
                <DatePicker
                  format={dateFormat}
                  style={{ width: "14rem" }}
                  onChange={onStartChange}
                />
              </div>
            </label>
          </div>
          <div style={{ width: "16rem" }}>
            <label>
              End Date*
              <div>
                <DatePicker
                  format={dateFormat}
                  style={{ width: "14rem" }}
                  onChange={onEndChange}
                />
              </div>
            </label>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            onClick={handleAddCancelCycle}
            style={{ marginRight: 8, width: "7rem" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddCycle}
            disabled={blockButton}
            style={{
              width: "7rem",
            }}
            type="primary"
          >
            Add
          </Button>
        </div>
      </Modal>

      <Modal // Edit cycle modal
        // title={
        //   <div style={{ marginTop: "2rem" }}>
        //     <h1 style={{ fontFamily: "Roboto-Bold", fontSize: "1.5rem" }}>
        //       Appraisal Cycle
        //     </h1>
        //     <p style={{ fontFamily: "Roboto-Italic", fontSize: "1rem" }}>
        //       Summary of employee working in a team.
        //     </p>
        //   </div>
        // }
        open={props?.showEditModal}
        onCancel={() => {
          props?.updateEditModal(false);
        }}
        onOk={handleEditOk}
        footer={null}
        width={600}
        bodyStyle={{ padding:0,height: "400px" }}
        closeIcon={<CloseOutlined style={{color:"white"}} />}
      >
        <div
          style={{
            backgroundColor: "#0086FF",
            height: "3.5rem",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
        >
          <img src={cycleWhite} style={{ width: "2rem", height: "1.5rem" }} />
          <h3
            style={{
              marginLeft: "0.5rem",
              color: "white",
              fontFamily: "Roboto-Bold",
            }}
          >
            Appraisal Cycle
          </h3>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Roboto-Italic",
              fontSize: "1rem",
              padding: "1rem",
            }}
          >
            Summary of Appraisal Cycle.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label>
            Appraisal Cycle*
            <div>
              <Select
                defaultValue={data?.cycle_appraisalCycle}
                disabled={disabled}
                style={{
                  width: "30rem",
                }}
                onChange={handleEditSelectChange}
                options={[
                  {
                    value: "April",
                    label: "April",
                  },
                  {
                    value: "October",
                    label: "October",
                  },
                ]}
              />
            </div>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
           
          <div style={{ width: "16rem", marginLeft: "2rem" }}>
            <label>
              Start Date*
              <div>
                <DatePicker
                  defaultValue={moment(sdate, dateFormat)}
                  // value={moment(startDate)}
                  format={dateFormat}
                  disabled={disabled}
                  style={{ width: "14rem" }}
                  onChange={onEditStartChange}
                />
              </div>
            </label>
          </div>
          <div style={{ width: "16rem" }}>
            <label>
              End Date*
              <div>
                <DatePicker
                  defaultValue={moment(edate, dateFormat)}
                  // value={moment(endDate)}
                  format={dateFormat}
                  disabled={disabled}
                  style={{ width: "14rem" }}
                  onChange={onEditEndChange}
                />
              </div>
            </label>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            onClick={() => {
              props?.updateEditModal(false);
            }}
            style={{ marginRight: 8, width: "7rem" }}
          >
            Cancel
          </Button>
          {editButton ? (
            <Button
              onClick={handleEditOk}
              style={{
                width: "7rem",
              }}
              type="primary"
            >
              Save
            </Button>
          ) : (
            <Button
              onClick={handleEdit}
              style={{
                width: "7rem",
                // backgroundColor: "#D3D3D3",
                // border: "1px solid #D3D3D3",
              }}
              type="primary"
            >
              Edit
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default AppraisalCycleModal;
