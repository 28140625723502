import React from "react";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Api from "services/Api";
import { useState, useEffect } from "react";
import { authActions } from "./store";
import { Row, Col, Divider } from 'antd';
import { google_client_id } from '../../credentials.json'

import avatar from '../../assets/img_avatar.png'
import googleIcon from '../../assets/google-icon.png'
const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // const [loggedIn, setLoggedIn] = useState(false);

  // useEffect(() => {
  //   const accessToken = localStorage.getItem("appraisalAccessToken");
  //   const user = localStorage.getItem("user");
  //   if (!accessToken) {
  //     setLoggedIn(false);
  //   } else {
  //     setLoggedIn(true);
  //     Api.header(accessToken);
  //   }
  // }, []);

  const onSuccessHandler = async (response) => {
    Api.post("/auth/google")
      .params({
        tokenId: response.tokenId,
      })
      .send((response, error) => {
        if (typeof response != "undefined") {
          Api.header(response.accessToken);
          dispatch(authActions.updateToken({ token: response.accessToken }));
          dispatch(authActions.updateUser({ user: response.user }));
          history.replace("/");
        }
      });
  };

  const onFailureHandler = (response) => {
    console.log('failed', response);
  };

  return (
    <>
       <h1 style={{color : "#137183",fontSize:"40px"}} justify="" align="middle">Welcome User!</h1>
      <Row justify="space-around" align="middle" >
        
     
 
  <hr className="sol"></hr>
 
        <Col span={24} style={{  display:"flex",justifyContent:"center",alignItems:"center"}}>
          {/* <DemoBox value={50}> */}
          <GoogleLogin
            clientId={google_client_id}
            // render={(renderProps) => (
            //   <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
            //     This is my custom Google button
            //   </button>
            // )}
            render={(renderProps) => ( <div className="card">
              <button onClick={renderProps.onClick} disabled={renderProps.disabled} style={{cursor:"pointer"}}>
                
        
        <img src={avatar} alt="Avatar" style={{width:"100%"}}></img>
        <h2><img src={googleIcon} alt="Google" style={{width:"15%"}}></img>&nbsp;&nbsp;&nbsp;Login using your &nbsp;&nbsp;&nbsp;Brigosha Mail</h2>
              </button></div>
            )}
            buttonText="Login using your Brigosha Mail"
            onSuccess={onSuccessHandler}
            onFailure={onFailureHandler}
            cookiePolicy={"single_host_origin"}
            className="full-width"
          />
          {/* </DemoBox> */}
        </Col> 
      </Row>
    </>
  );
};

export default Login;

 
