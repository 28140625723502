import React from "react";
import AdminPrivateRoute from "./AdminPrivateRoute";
import Team from "pages/admin/team";
import Users from "../users";
import AppraisalCycle from "../appraisal-cycle";

function RoutesPrivate() {
  return (
    <div>
      <AdminPrivateRoute exact path="/admin" role="Admin">
        <Users />
      </AdminPrivateRoute>
      <AdminPrivateRoute exact path="/admin/team" role="Admin">
        <Team />
      </AdminPrivateRoute>
      <AdminPrivateRoute exact path="/admin/appraisalLink" role="Admin">
        <AppraisalCycle/>
      </AdminPrivateRoute>
    </div>
  );
}

export default RoutesPrivate;
