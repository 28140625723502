import {
  MinusCircleTwoTone,
  PlusCircleOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Alert, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Api from "services/Api";
import { adminActions } from "./store";
import { Color } from "services/color";
import { CloseOutlined } from "@ant-design/icons";

import MinusIcon from "../../assets/minusIcon.svg";
import EditList from "../../assets/editListIcon.svg";
import PluseIcon from "../../assets/PluseIcon.svg";
import moment from "moment";
import { tuple } from "antd/es/_util/type";

const WorkSummaryModal = () => {
  const {
    teams,
    workSummaryModal,
    rowData,
    departmentDropdown,
    teamDropdown,
    managerDropdown,
  } = useSelector((state) => state.admin);

  const initialWorkSummary = rowData?.workSummary;
  console.log("rowwww", rowData);

  let [workSummary, setWorkSummary] = useState(initialWorkSummary);
  let [deletedWorkSummary, setDeletedWorkSummary] = useState([]);
  const [deletedId, setDeletedId] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [errorIndex, setErrorIndex] = useState(0);
  const [error, setError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [teamNameError, setTeamNameError] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [durationErrorType, setDurationErrorType] = useState("");
  const [teamErrorType, setTeamErrorType] = useState("");
  const [titleErrorType, setTitleErrorType] = useState("");

  const [depAssigned, setDepAssignedDate] = useState();
  const [releaseDate, setReleaseDate] = useState();
  const [monthDifference, setMonthDifference] = useState(0);

  const [departmentId, setDepartmentId] = useState();
  const [teamId, setTeamId] = useState();
  const [managerId, setManagerId] = useState();

  const [disableSave, setDisableSave] = useState(false);

  const [durationWarning, setDurationWarning] = useState("");
  const [blockButton, setBlockButton] = useState(true);
  const [counts, setCounts] = useState([1]);
  const [joinedDate, setJoinedDate] = useState(rowData?.joinedDate);
  const [btnTeam, setBtnTeam] = useState(true);
  const [btnManager, setBtnManager] = useState(true);
  const [managerDrop, setManagerDropdown] = useState();

  const dispatch = useDispatch();
  const divRef = useRef(null);

  // setWorkSummary(rowData?.workSummary)

  useEffect(() => {
    updateArray();
  }, [rowData?.workSummary, deletedWorkSummary]);

  // console.log(
  //   "Work Summary Data",
  //   initialWorkSummary,
  //   workSummary,
  //   deletedWorkSummary
  // );

  const options = teams.map((item, index) => {
    // console.log("Teams",item)
    return (
      <Select.Option
        key={index}
        label={`${item?.team_name} : ${item?.managername}`}
        title={`${item?.team_name} : ${item?.managername}`}
        value={item?.team_id}
      >
        {item?.team_name} : {item?.managername}
      </Select.Option>
    );
  });

  const updateArray = () => {
    setWorkSummary(rowData?.workSummary);
    setDeletedWorkSummary(deletedWorkSummary);
  };

  // console.log("workkk", workSummary);

  const handleOk = () => {
    let title = "please fill complete";
    let max_duration = 0;
    // console.log("Work Summary added");

    Api.patch("/admin/work-summary/?userId=" + rowData.id)
      .params({ workSummary, deletedWorkSummary })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          setError(false);
          setDurationError(false);
          setTeamNameError(false);
          setTitleError(false);
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
          // onCancel();
          dispatch(adminActions.updateWorkSummaryModal(false));
        }
      });
    if (workSummary?.length === 0 && deletedWorkSummary?.length === 0) {
      // console.log("Please add work Summary");
      setTitleError(true);
      setTitleErrorType("Please add work Summary");
      return;
    }

    if (deletedWorkSummary?.includes(undefined) && workSummary?.length === 0) {
      // console.log("Please add work Summary");
      setTitleError(true);
      setTitleErrorType("Please add work Summary");
      return;
    }

    if (
      JSON.stringify(
        workSummary.find((element) => JSON.stringify(element) === "{}")
      ) === "{}"
    ) {
      // console.log("please fill the details");
      setTitleError(true);
      setTitleErrorType("Please fill the details");
      return;
    }

    if (workSummary?.length > 0) {
      // console.log("Entered looping");
      const verifyObject = (objects) => objects?.workDuration && objects?.team;

      // Below code is to validate duplicate teams
      for (let i = 0; i <= workSummary.length - 1; i++) {
        if (workSummary[i].workDuration > 17) {
          setTitleError(true);
          setTitleErrorType(
            "Invalid Input! Work duration should be less than 18 Months."
          );
          return;
        }
        for (let j = 0; j <= workSummary.length - 1; j++) {
          if (i != j) {
            if (workSummary[i]?.team?.id === workSummary[j]?.team?.id) {
              // console.log("Duplicate Data", workSummary[i]?.team?.id, i);
              setTitleError(true);
              setTitleErrorType("Please select a different team!");
              return;
            }
          }
        }
      }

      // Below code is to find the sum of all the duration should be less than or equal to 18 months
      workSummary?.map((summary, index) => {
        if (summary?.workDuration) {
          max_duration += summary?.workDuration;
        }
      });

      if (max_duration > 17) {
        setDurationError(false);
        setTitleError(true);
        setTitleErrorType("Invalid input! Please add a valid work duration.");
        max_duration = 0;
        return;
      }

      // Below code is to find either duration or team exist or not in all the objects
      if (!workSummary?.every(verifyObject)) {
        workSummary?.map((summary, index) => {
          if (!summary?.workDuration || summary?.workDuration === null) {
            setErrorIndex(index);
            // console.log("Please enter work duration", summary, errorIndex);
            setDurationError(true);
            setTeamNameError(false);
            setTitleError(false);
            setDurationErrorType("Please enter a vaild month duration!");
          }
          if (!summary?.team?.id) {
            setErrorIndex(index);
            // console.log("Please select team", summary, errorIndex);
            setTeamNameError(true);
            setDurationError(false);
            setTitleError(false);
            setTeamErrorType("Please select team and manager name!");
          }
        });
        return;
      }
    }

    console.log("Work Summary added");

    setError(false);
    setTitleError(false);
    setDurationError(false);
    setTeamNameError(false);
    // setTimeout(() => {
    //   window.location.reload(false);
    // }, 2000);
  };

  const onCancel = () => {
    setError(false);
    setDurationError(false);
    setTeamNameError(false);
    setTitleError(false);
    setDeletedWorkSummary([]);

    dispatch(adminActions.updateWorkSummaryModal(false));
  };

  const onWorkDurationChange = (value, index) => {
    let newArray = [...workSummary];
    let newObject = { ...newArray[index] };
    newObject.workDuration = value;
    newArray[index] = newObject;
    setWorkSummary(newArray);
  };

  const onTeamChange = (value, index) => {
    let newArray = [...workSummary];
    let newObject = { ...newArray[index] };
    let selectedTeam = teams?.filter((item) => item.team_id === value);
    if (Object.keys(newObject).length === 0) {
      newObject = {
        team: {
          id: selectedTeam[0]?.team_id,
          name: selectedTeam[0]?.team_name,
          manager: { name: selectedTeam[0]?.managername },
        },
      };
    } else {
      newObject.team = {
        id: selectedTeam[0]?.team_id,
        name: selectedTeam[0]?.team_name,
        manager: { name: selectedTeam[0]?.managername },
      };
    }
    newArray[index] = newObject;
    setWorkSummary(newArray);
  };

  const deleteWorkSummary = (index) => {
    // console.log("Deleting Item", index);
    let newArray = [...workSummary];
    let newRowData = { ...rowData };
    setDeletedWorkSummary([...deletedWorkSummary, newArray[index].id]);
    newRowData.workSummary = newArray.filter(
      (item) => newArray.indexOf(item) !== index
    );
    // console.log("Filtered data", newRowData);
    dispatch(adminActions.updateRowData(newRowData));
    setError(false);
    setTeamNameError(false);
    setDurationError(false);
    if (counts.length > 0) {
      setCounts(counts.slice(0, counts.length - 1));
    }
  };

  const addWorkSummary = () => {
    const newArray = [...workSummary, ...[{ joinedDate: rowData?.joinedDate }]];
    console.log("newww", newArray);
    setWorkSummary(newArray);
    setCounts([...counts, counts.length + 1]);
  };

  // const handleJoinedDate = (date, dateString) => {
  //   console.log( dateString);Fdiv
  // };

  const handleAssignedDate = (index) => (date, dateString) => {
    console.log("department Assigned date", dateString, index);
    setDepAssignedDate(dateString);
    // console.log("workk",workSummary);
    const updateWorkSummary = [...workSummary];
    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      startDate: dateString,
    };
    setWorkSummary(updateWorkSummary);
  };
  const handleReleasedDate = (index) => (date, dateString) => {
    console.log("release date", dateString);
    setReleaseDate(dateString);

    const updateWorkSummary = [...workSummary];
    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      endDate: dateString,
    };
    setWorkSummary(updateWorkSummary);
  };

  const handleDepartmentSelect = (index) => (value) => {
    console.log(`selectedDep ${value}`);
    setDepartmentId(value);

    Api.get("/admin/getTeamDropdown")
      .params({ departmentId: value })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("team dropdown data", response);
          dispatch(adminActions.updateTeamDropDown(response));
          setBtnTeam(false);
        }
      });

    const updateWorkSummary = [...workSummary];
    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      departmentId: value,
    };
    setWorkSummary(updateWorkSummary);
  };
  const handleTeamSelect = (index) => (value) => {
    console.log(`selectedTeam ${value}`);
    setTeamId(value);
    Api.get("/admin/getManagerDropdown")
      .params({ departmentId: departmentId, teamId: value })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("manager dropdown data", response);
          // dispatch(adminActions.updateManagerDropDown(response));
          setManagerDropdown(response);
          setBtnManager(false);
        }
      });

    const updateWorkSummary = [...workSummary];
    updateWorkSummary[index] = { ...updateWorkSummary[index], teamId: value };
    setWorkSummary(updateWorkSummary);
  };
  const handleManagerSelect = (index) => (value) => {
    console.log(`selectedManger ${value}`);
    setManagerId(value);

    const updateWorkSummary = [...workSummary];
    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      managerId: value,
    };
    setWorkSummary(updateWorkSummary);
  };
  const handleDuration = (index) => (e) => {
    console.log("duration in months",e.target.value);
    const updateWorkSummary = [...workSummary];
    updateWorkSummary[index] = {
      ...updateWorkSummary[index],
      workDuration:e.target.value,
    };
    setWorkSummary(updateWorkSummary);
    // console.log("durations in months",e.target.value);
    // const duration = parseInt(e.target.value);
    // if (e.target.value === "" || duration >= 0) {
    //   setDurationWarning("");
    //   setDisableSave(false);
    //   const updateWorkSummary = [...workSummary];
    //   updateWorkSummary[index] = {
    //     ...updateWorkSummary[index],
    //     workDuration: duration,
    //   };
    //   setWorkSummary(updateWorkSummary);
    // // } else if(e.target.value > 18) {
    // //   setDurationWarning("More than 18 months is not allowed"); const updateWorkSummary = [...workSummary];
    // //   updateWorkSummary[index] = {
    // //     ...updateWorkSummary[index],
    // //     workDuration: duration,
    // //   };
    // //   setWorkSummary(updateWorkSummary);
    // //   setDisableSave(true);
    // }else{
    //   setDurationWarning("Negative values are not valid.");
    //   setDisableSave(true);
    // }
  };

  // console.log("listof", rowData?.joinedDate);
  // console.log("daaaaa",data);

  useEffect(() => {
    calculateMonthDifference();
  }, [depAssigned, releaseDate]);

  const calculateMonthDifference = () => {
    if (depAssigned && releaseDate) {
      const start = moment(depAssigned);
      const end = moment(releaseDate);

      const durationInMonths = end.diff(start, "months");

      setMonthDifference(durationInMonths);
    } else {
      setMonthDifference(0);
    }
  };

  // console.log("durationn",monthDifference);

  return (
    <Modal
      destroyOnClose={true}
      bodyStyle={{ padding: 0, height: "35rem" }}
      width={1000}
      style={{ top: 50 }}
      // title={
      //   <div>

      //     {titleError && (
      //       <div>
      //         {/* {index === workSummary.length - 1 && ( */}
      //         <h6 style={{ color: Color.red }}>{titleErrorType}</h6>
      //         {/* )} */}
      //       </div>
      //     )}
      //   </div>
      // }
      visible={workSummaryModal}
      // onOk={handleOk}
      // footer={null}
      onCancel={
        //   () => {
        //   dispatch(adminActions.updateWorkSummaryModal(false));
        // }
        onCancel
      }
      onOk={handleOk}
      okText="Save"
      cancelText="Cancel"
      // width={520}
      footer={false}
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
    >
      <div
        style={{
          backgroundColor: "#0086FF",
          height: "3.5rem",
          display: "flex",
          justifyContent: "flex-start",
          padding: "1rem",
        }}
      >
        <img
          src={EditList}
          style={{ width: "1.5rem", height: "1.2rem", marginTop: "0.4rem" }}
        />
        <h3 style={{ marginLeft: "0.5rem", color: "white", fontSize: "20px" }}>
          Edit Summary
        </h3>
      </div>
      
      <text
        style={{
          // fontFamily: "Roboto-Italic",
          fontSize: "1.2rem",
          marginLeft: "2rem",
          marginTop: "0rem",
          position: "relative",
          top: "1rem",
          zIndex: 1,
          display:"flex",
          flexDirection: "column",
        }}
      >
        <span style={{fontWeight:"bold",marginLeft:"0rem"}}>Name:&nbsp;&nbsp;<a style={{color:"0086FF",fontWeight:"bold"}}>{rowData?.name}</a></span>
          
          <br></br>
       <span style={{marginTop:"-2rem"}}> Summary of employee working in a team.</span>
        
      </text>

      <div style={{ height: "19rem", overflow: "auto" ,marginTop:"15px"}}>
        {workSummary?.length > 0
          ? workSummary?.map((item, index) => {
              // console.log("outtt",item);
              const defaultValue = rowData?.joinedDate
                ? moment(rowData?.joinedDate)
                : null;
              const defaultValue1 = item?.startDate
                ? moment(item?.startDate)
                : null;
              const defaultValue2 = item?.endDate
                ? moment(item?.endDate)
                : null;
              return (
                <div>
                  <div
                    key={index}
                    style={{
                      width: "60rem",
                      marginLeft: "1.2rem",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "1.5rem",
                     
                    }}
                  >
                    {/* <div style={{ fontSize: "1rem", marginRight: "50rem" }}>
                    
                      Team: {counts[index]}
                    </div> */}
                    <a onClick={() => deleteWorkSummary(index)}>
                      <img
                        src={MinusIcon}
                        style={{
                          width: "1rem",
                          marginRight: "0.5rem",
                          marginTop: "0.1rem",
                          cursor: "pointer",
                        }}
                      />
                    </a>
                    <h3>Remove</h3>
                  </div>
                  <div
                    style={{
                      border: "1px solid #E8E8E8",
                      width: "60rem",
                      height: "15rem",
                      marginLeft: "1.2rem",
                      boxShadow: "0 0 10px 1px lightgray",
                      borderRadius: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1rem",
                      }}
                    >
                      <div>
                        <label>
                          Joined Date{" "}
                          <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <DatePicker
                            // onChange={handleJoinedDate}
                            disabled
                            style={{ width: "12rem" }}
                            defaultValue={defaultValue}
                          />
                        </div>
                      </div>
                      <div>
                        <label>
                          Department Assigned Date{" "}
                          <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <DatePicker
                            key={index}
                            onChange={handleAssignedDate(index)}
                            style={{ width: "12rem" }}
                            defaultValue={defaultValue1}
                          />
                        </div>
                      </div>
                      <div>
                        <label>
                          Department <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          {console.log("objectitem",item)}
                          <Select
                            showSearch
                            key={index}
                            onChange={handleDepartmentSelect(index)}
                            style={{ width: "12rem" }}
                            defaultValue={ item?.department?.name}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {departmentDropdown &&
                              Object.values(departmentDropdown).map((item) => (
                                <Option
                                  key={item?.id}
                                  value={item?.id}
                                  label={item?.name}
                                  title={item?.name}
                                >
                                  {item?.name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "1rem",
                      }}
                    >
                      <div>
                        <label>
                          Team Name <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <Select
                            key={index}
                            onChange={handleTeamSelect(index)}
                            style={{ width: "12rem" }}
                            defaultValue={item?.team?.name}
                          
                            disabled={btnTeam}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {teamDropdown &&
                              Object.values(teamDropdown).map((item) => (
                                <Option
                                  key={item?.id}
                                  value={item?.id}
                                  label={item?.name}
                                  title={item?.name}
                                >
                                  {item?.name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                      <div>
                        <label>
                          Manager Name{" "}
                          <span style={{ color: Color.red }}>*</span>
                        </label>
                        <div>
                          <Select
                            key={index}
                            onChange={handleManagerSelect(index)}
                            style={{ width: "12rem" }}
                            defaultValue={item?.team?.manager?.name}
                            disabled={btnManager}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {managerDrop &&
                              
                              <Option
                              key={managerDrop?.id}
                              value={managerDrop?.id}
                              label={managerDrop?.name}
                              title={managerDrop?.name}
                            >
                              {managerDrop?.name}
                            </Option>
                              
                              }
                          </Select>
                        </div>
                      </div>
                      <div>
                        <label>
                          Release Date{" "}
                          {/* <span style={{ color: Color.red }}>*</span> */}
                        </label>
                        <div>
                          <DatePicker
                            key={index}
                            onChange={handleReleasedDate(index)}
                            style={{ width: "12rem" }}
                            defaultValue={defaultValue2}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "4rem",
                        marginTop: "1rem",
                      }}
                    >
                      <div>
                        <label>Duration</label>
                        <div>
                          <Input
                          type="number"
                            onChange={handleDuration(index)}
                            value={
                              item?.workDuration
                                ? item.workDuration
                                : monthDifference || ""
                            }
                            style={{ width: "12rem" }}
                            addonAfter="months"
                            autoFocus={index === workSummary.length - 1}
                          />
                          {/* {durationWarning && (
                            <Alert
                              message={durationWarning}
                              type="warning"
                              showIcon
                              // closable
                              style={{marginLeft:"13rem",marginTop:"-2rem"}}
                            />
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.5rem",
        }}
      >
        <a onClick={() => addWorkSummary()} style={{ color: "black" }}>
          <img
            src={PluseIcon}
            style={{ width: "1rem", marginLeft: "1.5rem" }}
          />
          <h3 style={{ color: "#0086FF", fontWeight: "bold" }}>Add Team</h3>
          {/* <PlusCircleOutlined /> */}
        </a>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <Button
            style={{
              width: "8rem",
              border: " 1px solid #0086FF",
              borderRadius: "4px",
              color: "#0086FF",
              marginRight: "2rem",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            style={{
              width: "8rem",
              border: " 1px solid #0086FF",
              borderRadius: "4px",
            }}
            onClick={handleOk}
            disabled={disableSave}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WorkSummaryModal;
