import { Fragment } from "react";
import WorkSummary from "./work-summary";
import AppraisalForm from "./appraisal-form";
import ThankYou from "./thank-you";
import CompanyFeedbackForm from "./companys-feedback-form";
import { AssociateStatus } from "./associate-status";
import Api from "services/Api";
import { useDispatch, useSelector } from "react-redux";
import { associateActions } from "./store";
import ManagerFeedbackForm from "./manager-feedback-form";
import { useEffect } from "react";

const AssociateHome = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.get("/associate?userId=" + user.id)
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(associateActions.updateAssociate({ associate: response }));
        }
      });
  }, []);

  const { associate } = useSelector((state) => state.associate);

  let page = null;
  if (
    associate?.userStatus === AssociateStatus.none &&
    associate?.appraisalActive === true
  ) {
    page = <WorkSummary />;
  } else if (
    associate?.userStatus === AssociateStatus.workSummaryAccepted &&
    associate?.appraisalActive === true
  ) {
    page = <AppraisalForm />;
  } else if (
    associate?.userStatus === AssociateStatus.appraisalFormFilled &&
    associate?.appraisalActive === true
  ) {
    page = <ManagerFeedbackForm />;
  } else if (
    associate?.userStatus === AssociateStatus.managerFeedbackFilled &&
    associate?.appraisalActive === true
  ) {
    page = <CompanyFeedbackForm />;
  } else if (
    associate?.userStatus === AssociateStatus.completed ||
    associate?.userStatus === AssociateStatus.none
  ) {
    page = <ThankYou />;
  }
  return <Fragment>{page}</Fragment>;
};

export default AssociateHome;
