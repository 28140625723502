import { Layout, theme, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

import Routes from "components/routes";
import HeaderLayout from "components/Layout/Header";
import brigosha_logo from "assets/brigosha_logo.svg";
import "antd/dist/antd.css";

const App = () => {
 
  return (
    <>
      <HeaderLayout />
      <Routes />
    </>
  );
};
export default App;
