import React, { useEffect, useState } from "react";
import { Button, Input, Table, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import { PlusCircleTwoTone } from "@ant-design/icons";
import Api from "services/Api";
import { useDispatch, useSelector } from "react-redux";

import teamWhite from "../../assets/teamWhite.svg";
import departmentWhite from "../../assets/department white.svg";
import { CloseOutlined } from "@ant-design/icons";

function TeamModal(props) {
  // const { teams } = useSelector((state) => state.admin);
  const data = props?.data;
  const data1Drop = props?.data2;
  const data2Drop = props?.dataDrop;
  // console.log("datsss", data);
  
  const { refreshEffect } = useSelector((state) => state.admin);
  const dataValue = props?.data1;
  const dispatch =useDispatch();
  // console.log("wwwwwwwww", props);
  const [disabled, setDisabled] = useState(true);
  const [isTeamDisabled, setIsTeamDisabled] = useState(true);
  const [isManagerDisabled, setIsManagerDisabled] = useState(true);
  const [editButton, setSaveButton] = useState(false);

  const [teamName, setTeamName] = useState();
  const [loader, setLoader] = useState(false);

  const [addTeam, setAddTeam] = useState();
  const [ManagerAddId, setManagerAddId] = useState();
  const [ManagerEditId, setManagerEditId] = useState();

  const [ManagerDrop, setManagerDpId] = useState();
  const [teamDrop, setTeamDrop] = useState([]);
  const [depId, setDepId] = useState();
  const [managerDrop, setManagerDrop] = useState();

  const [disableSave, setDisableSave] = useState(true);
  const [memberData, setMemberData] = useState([]);
  const [blockButton, setBlockButton] = useState(true);
  
  const [addDepartment, setAddDepartment] = useState();
  
  const Option = Select.Option;

  const handleEditTeamName = (value, key) => {
    setTeamName(value);
    console.log("selected Team Name", value, key.key);
    Api.get(`/admin/getManagerDropdown`)
      .params({ departmentId: depId, teamId: key.key })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Add Manager ", response);
          setManagerDrop(response);
        }
      });
    setIsManagerDisabled(false);
  };

  useEffect(() => {
    setLoader(true);
    Api.get(`/admin/getManagerDropdownData`)
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Add Manager ", response);
          setManagerDpId(response);
          setLoader(false);
        }
      });
  }, []);

  useEffect(() => {
    if (addTeam && depId && ManagerAddId) {
      setBlockButton(false);
    } else {
      setBlockButton(true);
    }
    if (addDepartment) {
      setBlockButton(false);
    }
  }, [addTeam, depId, ManagerAddId, addDepartment]);

  if (dataValue) {
    useEffect(() => {
      Api.get(`/admin/memberTeamData`)
        .params({ teamId: dataValue?.id })
        .send((response, error) => {
          if (
            typeof response !== "undefined" &&
            response?.show?.type !== "error"
          ) {
            console.log("team id", response);
            setMemberData(response);
            setLoader(false);
          } else {
          }
        });
    }, []);
  }

  const handleAddTeamName = (e) => {
    console.log("team Name", e.target.value);
    setAddTeam(e.target.value);
  };

  const handleAddManagerName = (value) => {
    console.log(`selectedManager ${value}`);
    setManagerAddId(value);
  };

  const handleAddDep = (value) => {
    console.log("selected dep is", value);
    setDepId(value);
  };

  const handleEditManagerName = (value) => {
    console.log(`EditManagerselected ${value}`);
    setManagerEditId(value);
    setDisableSave(false);
  };

  // console.log("maanaana",memberData);

  const postApi = () => {
    console.log("clickeedpost");
    Api.post(`/admin/createTeam`)
      .params({
        teamName: addTeam,
        managerId: ManagerAddId,
        departmentId: depId,
      })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          props?.refresh();
          console.log("Add Team users", response);
        }
      });
  };

  const handleTeamOk = () => {
    postApi();
    props.updateTeamModalOpen(false);
  };

  // console.log("data of teamid ", data?.teams_id);
  // console.log("data of manager id", ManagerEditId);

  const handleEditOk = () => {
    props?.updateShowEditModal(false);
    setDisabled(false);

    Api.post(`/admin/createTeam`)
      .params({ teamName, departmentId: depId, managerId: ManagerEditId })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Edited List Team users", response);
          props?.refresh();
        }
      });
  };

  const handleEdit = () => {
    setDisabled(false);
    setSaveButton(true);
  };

  const handleDepartmentOk = () => {
    // console.log("cretated")
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "age",
      render:(val,row)=>{
        return(
          <div>
            {/* {console.log("memeee",row?.user?.name)} */}
            {row?.user?.name}
          </div>
        )
      }
    },
    {
      title: "Status",
      dataIndex: "",
      key: "",
      render:(val,row)=>{
        return(
          <div>
            {/* {console.log("memeee",row?.user?.status)} */}
            {row?.user?.status}
          </div>
        )
      }
    },
  ];

  const handleDepDrop = (value) => {
    console.log("selected dep", value);
    setDepId(value);
    Api.get(`/admin/getTeamDropdown`)
      .params({ departmentId: value })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Add Team users", response);
          setTeamDrop(response);
        }
      });
    setIsTeamDisabled(false);
  };

  const handleDepartment = (e) => {
    setAddDepartment(e.target.value);
  };
  const handleCreateDepartment = () => {
    Api.post(`/admin/createDepartment`)
      .params({ name: addDepartment })
      .send((response, error) => {
        if (typeof response !== "undefined") {
          // console.log(" department ", response);
          props.updateDepartmentModalOpen(false);
          setTimeout(() => {
            window.location.reload(false)
          }, 1000);
        }
      });
  };

  // console.log("is trueee",addTeam,depId,ManagerAddId);

  return (
    <>
      <Modal // modal for Add Team members
        bodyStyle={{ padding: 0, height: "22rem" }}
        open={props?.TeamModalOpen}
        onCancel={() => {
          props.updateTeamModalOpen(false);
        }}
        onOk={handleTeamOk}
        footer={null}
        width={600}
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
      >
        <div
          style={{
            backgroundColor: "#0086FF",
            height: "3.5rem",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
        >
          <img src={teamWhite} style={{ width: "2rem", height: "1.5rem" }} />
          <h3
            style={{
              marginLeft: "0.5rem",
              color: "white",
              fontFamily: "Roboto-Bold",
            }}
          >
            Team Information
          </h3>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Roboto-Italic",
              fontSize: "1rem",
              padding: "1rem",
            }}
          >
            Create a new team
          </p>
        </div>
        <div
          style={{
            width: "14rem",
            marginLeft: "2.3rem",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          <label>
            Team Name <span style={{ color:"#FF0000" }}>*</span>
            <Input
              required
              placeholder="Enter the team name"
              onChange={handleAddTeamName}
            />
          </label>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            justifyContent: "space-around",
            marginTop: "0.5rem",
          }}
        >
          <div style={{ width: "14rem" }}>
            <label>
              Department <span style={{ color:"#FF0000" }}>*</span>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "14rem" }}
                placeholder="Choose Department name"
                onChange={handleAddDep}
                // onSearch={onDepSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {data2Drop &&
                  Object.values(data2Drop).map((item) => (
                    <Option
                      key={item?.id}
                      value={item?.id}
                      label={item?.name}
                      title={item?.name}
                    >
                      {item?.name}
                    </Option>
                  ))}
              </Select>
            </label>
          </div>
          <div style={{ width: "14rem" }}>
            <label>
              Manager Name <span style={{ color:"#FF0000" }}>*</span>
              <Select
                style={{ width: "14rem" }}
                placeholder="Choose manager name"
                onChange={handleAddManagerName}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {/* {ManagerDrop &&
                  Object.values(ManagerDrop).map((item,index) => {
                    console.log("mann", item?.manager?.name, item?.manager?.id);
                    return (
                      <div key={`${item?.manager?.id}-${index}`}>
                      <Option
                        value={item?.manager?.id}
                        label={item?.manager?.name}
                        title={item?.manager?.name}
                      >
                        {item?.manager?.name}
                      </Option>
                    </div>
                    );
                  })} */}
                {ManagerDrop &&
                  Object.values(ManagerDrop).map((item, index) => {
                    console.log("mann", item?.manager?.name, item?.manager?.id);
                    return (
                      <Select.Option
                        key={`${item?.manager?.id}-${index}`}
                        value={item?.manager?.id}
                        label={item?.manager?.name}
                        title={item?.manager?.name}
                      >
                        {item?.manager?.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </label>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              props.updateTeamModalOpen(false);
            }}
            style={{ marginRight: 8, width: "7rem" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleTeamOk}
            disabled={blockButton}
            style={{ width: "7rem" }}
            type="primary"
          >
            Add
          </Button>
        </div>
      </Modal>

      <Modal //modal for Edit button
        open={props?.showEditModal}
        onCancel={() => {
          props.updateShowEditModal(false);
        }}
        onOk={handleEditOk}
        footer={null}
        width={600}
        bodyStyle={{ padding: 0, height: "22rem" }}
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
      >
        <div
          style={{
            backgroundColor: "#0086FF",
            height: "3.5rem",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
        >
          <img
            src={teamWhite}
            style={{ width: "1.2rem", height: "1rem", marginTop: "0.2rem" }}
          />
          <h3
            style={{
              marginLeft: "0.5rem",
              color: "white",
              // fontFamily: "Roboto-Bold",
            }}
          >
            Team Information
          </h3>
        </div>
        <div>
          <p
            style={{
              // fontFamily: "Roboto-Italic",
              fontSize: "1rem",
              padding: "1rem",
            }}
          >
            Summary of employee working in a team.
          </p>
        </div>
        <div
          style={{
            width: "14rem",
            marginLeft: "2.3rem",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          <label>
            Department <span style={{ color:"#FF0000" }}>*</span>
            <Select
              style={{ width: "14rem" }}
              disabled={disabled}
              defaultValue={data?.department?.name}
              onChange={handleDepDrop}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {data1Drop &&
                Object.values(data1Drop).map((item) => (
                  <Option
                    key={item?.id}
                    value={item?.id}
                    label={item?.name}
                    title={item?.name}
                  >
                    {item?.name}
                  </Option>
                ))}
            </Select>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            justifyContent: "space-around",
            marginTop: "0.5rem",
          }}
        >
          <div style={{ width: "14rem" }}>
            <label>
              Team Name <span style={{ color:"#FF0000" }}>*</span>
              <Select
                style={{ width: "14rem" }}
                disabled={isTeamDisabled}
                defaultValue={data?.name}
                onChange={handleEditTeamName}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {Object.values(teamDrop).map((item) => (
                  <Option
                    key={item?.id}
                    value={item?.name}
                    label={item?.teams_name}
                    title={item?.teams_name}
                  >
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </label>
          </div>
          <div style={{ width: "14rem" }}>
            <label>
              Manager Name <span style={{ color:"#FF0000" }}>*</span>
              <Select
                style={{ width: "14rem" }}
                disabled={isManagerDisabled}
                defaultValue={data?.manager?.name}
                onChange={handleEditManagerName}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {/* {Object.values(managerDrop).map((item) => console.log("managaa",item)( */}
                <Option
                  key={managerDrop?.id}
                  value={managerDrop?.id}
                  label={managerDrop?.name}
                  title={managerDrop?.name}
                >
                  {managerDrop?.name}
                </Option>
                {/* ))} */}
              </Select>
            </label>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              props?.updateShowEditModal(false);
            }}
            style={{ marginRight: 8, width: "7rem" }}
          >
            Cancel
          </Button>

          {editButton ? (
            <Button
              onClick={handleEditOk}
              disabled={disableSave}
              style={{
                width: "7rem",
              }}
              type="primary"
            >
              Save
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={handleEdit}
              style={{
                width: "7rem",
                // backgroundColor: "#D3D3D3",
                // border: "1px solid #D3D3D3",
              }}
            >
              Edit
            </Button>
          )}
        </div>
      </Modal>

      <Modal // modal for member
        visible={props?.teamNameModal}
        onCancel={() => {
          props?.SetTeamNameModal(false);
        }}
        style={{ borderRadius: "1.1rem" }}
        footer={null}
        width={800}
        bodyStyle={{ height: "30rem" }}
        // closeIcon={<CloseOutlined style={{color:"white"}} />}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            <h2>{dataValue?.name}</h2>
          </div>
          <Table
            dataSource={memberData}
            columns={columns}
            loading={loader}
            size="small"
            scroll={{
              y: 300,
            }}
            pagination={{
              position: "bottomLeft", // change the position to "bottomLeft"
              // pageSize: pageSize, // set pageSize to 10 to display only 10 records
              size: "small",
              pageSizeOptions: [5, 10, 20, 30, 50],
              // current: Number(meta?.currentPage),
              // total: meta?.totalItems ? meta?.totalItems : 0,
              // onChange: (page, pageSize) => handlePageChange(page, pageSize),
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
          
        </div>
      </Modal>

      <Modal
        bodyStyle={{ padding: 0, height: "20rem" }}
        visible={props?.departmentModalOpen}
        onCancel={() => {
          props?.updateDepartmentModalOpen(false);
        }}
        style={{ borderRadius: "1.1rem" }}
        footer={null}
        width={500}
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
      >
        <div
          style={{
            backgroundColor: "#0086FF",
            height: "3.5rem",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
        >
          <img
            src={departmentWhite}
            style={{ width: "2rem", height: "1.5rem" }}
          />
          <h3
            style={{
              marginLeft: "0.5rem",
              color: "white",
              fontFamily: "Roboto-Bold",
            }}
          >
            Department
          </h3>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Roboto-Italic",
              fontSize: "1rem",
              padding: "1rem",
            }}
          >
            After creating department kindly add team
          </p>
        </div>
        <div
          style={{
            // width: "30rem",
            marginLeft: "2.3rem",
            fontSize: "14px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <label style={{}}>
            Department Name <span style={{ color:"#FF0000" }}>*</span>
            <Input
              required
              placeholder="Create Department Name"
              onChange={handleDepartment}
              style={{ marginTop: "8px" }}
            />
          </label>
        </div>

        <div
          style={{
            textAlign: "center",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              props.updateDepartmentModalOpen(false);
            }}
            style={{ marginRight: 8, width: "7rem" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateDepartment}
            disabled={blockButton}
            style={{ width: "7rem" }}
            type="primary"
          >
            Add
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default TeamModal;
