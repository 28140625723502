import React from "react";
import classes from "./Header.module.css";
import { size } from "lodash";
import { Button, Dropdown, Menu, Row, Select, Space, Layout } from "antd";
import { AntDesignOutlined } from "@ant-design/icons";

import { Color } from "services/color";
import brigosha_logo from "assets/brigosha_logo.svg";
import faq_icon from "assets/faq_icon.svg";
import log_out_icon from "assets/log_out_icon.svg";
import logo_icon from "../../assets/Logo_Icon.svg";
import { authActions } from "pages/login/store";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDown } from "react-icons/ai";
import "./index.css";
import { useHistory } from "react-router-dom";
import Manager_FAQ from "../../assets/Manager_FAQ.pdf";
import Associate_FAQ from "../../assets/Associate_FAQ.pdf";
import { useState, useEffect } from "react";
import { adminActions } from "pages/admin/store";
import ProfileIcon from "../../assets/profileIcon.svg";
import logoutIcon from "../../assets/noun-logout-5695200 (1).svg"

function AssociateHeader() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Sider, Header, Content } = Layout;
  // const {Option} = Select
  // console.log("history", history, useHistory());
  const { token, user } = useSelector((state) => state.auth);

  const roles = ["Admin", "Manager"];

  const logoutHandler = () => {
    dispatch(authActions.removeUser());
  };

  const onChangeRole = (userRole) => {
    // console.log("User state changed into",{...user, role: userRole})
    dispatch(authActions.changerRole({ ...user, role: userRole }));
    userRole === "Manager" ? history.push("/manager") : history.push("/admin");
    window.location.reload(true);
  };

  const year = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState("");

  const onDowloadFAQ = () => {
    // console.log("Clicking on Download", user?.role);
    if (user?.role === "Admin") {
      // console.log("Download Admin FAQ");
      return;
    }
    if (user?.role === "Manager") {
      window.open(Manager_FAQ);
      // console.log("Download Manager FAQ");
      return;
    }
    if (user?.role === "Associate") {
      window.open(Associate_FAQ);
      // console.log("Download Associate FAQ");
      return;
    }
  };

  useEffect(() => {
    const getCurrentCycle = () => {
      const date = new Date();
      const month = date.getMonth();
      const cycles = [
        { cycle: "April", months: [12, 1, 2, 3, 4, 5] },
        { cycle: "October", months: [6, 7, 8, 9, 10, 11] },
      ];

      for (const cycle of cycles) {
        if (cycle.months.includes(month)) {
          return cycle.cycle;
        }
      }

      return "";
    };

    setCurrentMonth(getCurrentCycle());
  }, []);

  return (
    <div>
      <div
        style={{ boxShadow: `0px 4px 5px ${Color.midGrey}` }}
        className={classes.header}
      >
        <img src={brigosha_logo} alt="" />
        <div
          style={{
            width:"100%",
            display:"flex",
            justifyContent:"center",
            marginTop:"1rem"
          }}
        >
          <h2  style={{
            fontSize: "1.9rem",
            fontFamily: "Roboto-Bold",
          }}>Appraisal</h2>
        </div>
        <div style={{ marginRight: "3rem", display: "flex" }}>
          <div style={{ marginLeft: "1rem" }} className="logout">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item style={{ background: "none", padding: "0" }}>
                    <Button
                      ghost
                      style={{
                        width: "100%",
                        margin: "0",
                      }}
                      type="text"
                      onClick={logoutHandler}
                    >
                      <span
                        style={{
                          fontFamily: "Roboto-Bold",
                        }}
                      >
                        Logout
                      </span>
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              <div
                style={{
                  width: "10rem",
                  height: "2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: "1.8rem",
                    height: "1.8rem",
                    margin: "0",
                    padding: "0",
                  }}
                  src={ProfileIcon}
                  alt=""
                />
                <h6
                  style={{
                    margin: "0 0 0 0.5rem ",
                    fontFamily: "Roboto-Bold",
                    fontSize: "1rem",
                  }}
                >
                  {user?.name}
                </h6>
                <img src={logoutIcon}
                    style={{
                      width:"1.1rem",
                      color: Color.darkGrey,
                      margin: "0 0.5rem",
                      cursor:"pointer"
                    }}
                  />
              </div>
            </Dropdown>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default AssociateHeader;
