import { Button, Table, Select, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { BulbOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import Api from "services/Api";
import { adminActions } from "./store";
import WorkSummaryModal from "./work-summary-modal";
import refreshIcon from "../../assets/refresh-Black.svg";
import CommonModal from "./commonModal";
import { AiFillFilter, AiFillCaretUp, AiFillCaretDown } from "services/Icons";
import { Color } from "services/color";
import classes from "../styles/userTable.module.css";
import "./tableHeader.css";
import ManualIcon from "../../assets/manual.png";
import UserIcon from "../../assets/userIcon.svg";
import cycleStatus from "../../assets/refresh-cw.svg";

const UserTable = (props) => {
  const {
    users,
    meta,
    designationList,
    cycleList,
    statusList,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    sorting,
    page,
    refreshEffect,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const history = useHistory();
  const Option = Select.Option;

  const [windowHeight, setWindowHeight] = useState(window.screen.height);
  const [pageSize, setPageSize] = useState(10);
  const [selectedValue, setSelectedValue] = useState(null);
  const [initialFilter, setInitialFilter] = useState({
    appraisalFilter: appraisalFilter,
    designationFilter: designationFilter,
    cycleFilter: cycleFilter,
    formStatusFilter: formStatusFilter,
  });

  const [filterStatusChanged, setFilterStChanged] = useState(false);
  const [filterLevelChanged, setFilterLevelChanged] = useState(false);
  const [filterFormChanged, setFilterFormChanged] = useState(false);
  const [filterCycleChanged, setFilterCycleChanged] = useState(false);
  

  useEffect(() => {
    dispatch(adminActions.updateFilter(initialFilter));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
    // console.log("Window Size", windowHeight);
  }, [initialFilter,windowHeight, page,]);

  

  // console.log("refresh",refreshEffect);
  // {refreshEffect?(
    // setInitialFilter({
    //   appraisalFilter:"",
    //   designationFilter: "",
    //   cycleFilter: "",
    //   formStatusFilter: "",
    // })
    // console.log("refressssing")
  // ):console.log("not refreshing")}
  useEffect(() => {
    if (refreshEffect) {
      setInitialFilter({
        appraisalFilter: "",
        designationFilter: "",
        cycleFilter: "",
        formStatusFilter: "",
      });
    } else {
      console.log("not refreshing");
    }
  }, []);
  
  useEffect(() => {
    if (history.action === "POP" || history.action === "PUSH") {
      dispatch(adminActions.updateSearchText(""));
    }
  }, [history]);

  const hanldeSelect = (value) => {
    console.log("selected value: " + value);
    setSelectedValue(value);
    setSelectedValue(null);
  };
  const handlePageChange = (page, pageSize, value) => {
    console.log("ppp", page, pageSize, value);
    setPageSize(pageSize);
    dispatch(adminActions.updatePage({ page: page, pageSize: pageSize }));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
  };

  const handleAppraisalfilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({
      ...initialFilter,
      appraisalFilter:
        value === undefined ? "" : value === "Active" ? true : false,
    });
    setFilterStChanged(!filterStatusChanged);
  };
  const handleCycleFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({ ...initialFilter, cycleFilter: value });
    setFilterCycleChanged(!filterCycleChanged);
  };
  const handleDesignationFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({ ...initialFilter, designationFilter: value });
    setFilterLevelChanged(!filterLevelChanged);
  };
  const handleFormStatusFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({ ...initialFilter, formStatusFilter: value });
    setFilterFormChanged(!filterFormChanged);
  };
  const handleSortByName = (value) => {
    value === sorting
      ? dispatch(
          adminActions.updateSortFilter({ ...{}, sortByName: undefined })
        )
      : dispatch(adminActions.updateSortFilter({ ...{}, sortByName: value }));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
    console.log("Sorting Filter by name", value);
  };

  const openCommonModal = (row) => {
    console.log("Common Modal Data", row);
    // setAppraisalCycleOpen(true);
    dispatch(
      adminActions.updateUser_Cycle_Designation({
        Id: row?.id,
        defaultCycle: row?.appraisalCycle,
        defaultLevel: row?.associateLevel,
      })
    );
    dispatch(adminActions.updateDesignationModal(true));
  };

  const onAppraisalStatusClick = (data) => {
    const appraisalActive = data.appraisalActive ? false : true;
    Api.patch("/admin/users/?userId=" + data.id)
      .params({ appraisalActive })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          dispatch(adminActions.updateSingleUser({ user: response.data }));
          // dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
        }
      });
  };

  const onWorkSummaryClick = (data) => {
    dispatch(adminActions.updateWorkSummaryModal(true));
    dispatch(adminActions.updateRowData(data));
    // console.log("Row data for workSummary", data);
  };

  const appraisalOptions = {
    active: "Active",
    inactive: "Inactive",
  };

  const content = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#696969",
        }}
      >
        Appraisal Cycle
      </h4>
    </div>
  );
  const content1 = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#696969",
        }}
      >
        Appraisal Form Status
      </h4>
    </div>
  );
  const content2 = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#696969",
        }}
      >
        Appraisal Status
      </h4>
    </div>
  );

  const [hovered, setHovered] = useState(null);
  const [hoveredCycle, setHoveredCycle] = useState(null);
  const [hoveredStatus, setHoveredStatus] = useState(null);

  const handleMouseEnterLevel = (rowId) => {
    setHovered(rowId);
  };

  const handleMouseLeaveLevel = () => {
    setHovered(null);
  };
  const handleMouseEnterCycle = (rowId) => {
    setHoveredCycle(rowId);
  };

  const handleMouseLeaveCycle = () => {
    setHoveredCycle(null);
  };
  const handleMouseEnterStatus = (rowId) => {
    setHoveredStatus(rowId);
  };

  const handleMouseLeaveStatus = () => {
    setHoveredStatus(null);
  };

  const designationContent = <div>Change Designation</div>;
  const cycleContent = <div>Change Cycle</div>;
  const statusContent = <div>Change Status</div>;

  const sortAscending = <h4>Click to sort ascending </h4>;
  const sortDescending = <h4>Click to sort descending </h4>;

// if(filterChanged){

//   console.log("initalll trueee",filterChanged);
// }else{
//   console.log("initalll falsee")
// }
  const columns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "5rem",
            marginLeft: "1rem",
            fontWeight:"bold"
          }}
        >
          {/* <h4
            style={{
              fontSize: "1rem",
              paddingTop: "0.5rem",
              color: "#696969",
            }}
          >
            Associate
          </h4> */}
          Associate
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "1rem",
              marginBottom: "8px",
            }}
          >
            <Popover content={sortAscending}>
              <Button
                type="text"
                style={{ height: "0.6rem", margin: "0", padding: "0" }}
                onClick={() => handleSortByName("ASC")}
              >
                <AiFillCaretUp
                  style={{
                    color:
                      sorting === "" ||
                      sorting === null ||
                      sorting === undefined
                        ? "#787b83"
                        : sorting === "ASC"
                        ? Color.modalblue
                        : "#787b83",
                  }}
                />
              </Button>
            </Popover>
            <Popover content={sortDescending}>
              <Button
                type="text"
                style={{ height: "0.8rem", margin: "0", padding: "0" }}
                onClick={() => handleSortByName("DESC")}
              >
                {
                  <AiFillCaretDown
                    style={{
                      color:
                        sorting === "" ||
                        sorting === null ||
                        sorting === undefined
                          ? "#787b83"
                          : sorting === "DESC"
                          ? Color.modalblue
                          : "#787b83",
                    }}
                  />
                }
              </Button>
            </Popover>
          </div>
        </div>
      ),
      dataIndex: "associate",
      key: "associate",

      // width: "16%",

      render: (val, row) => {
        let flag =row?.manualChange
        return (
          <div style={{ display: "flex" }}>
            <div>
              <img
                style={{
                  height: "1.5rem",
                  width: "1.5rem",
                  marginRight: "0.2rem",
                  marginTop: "1rem",
                }}
                src={UserIcon}
              />
            </div>
            <div
              style={{
                color: "black",
                width: "5rem",
                whiteSpace: "nowrap",
                marginLeft: "0.2rem",
              }}
            >
              <a
                onClick={() => {
                  history.push({
                    pathname: "/associatesDetails",
                    state: row?.id,
                  });
                }}
                type="link"
              >
                <Popover content={row?.name}>
                  <h5
                    style={{
                      fontSize: "1rem",
                      width: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden ",
                      cursor: "pointer",
                      color: "#0086FF",
                      margin: "0",
                    }}
                  >
                    {row?.name}
                  </h5>
                </Popover>
              </a>
              <Popover content={row?.designation}>
                <h6
                  style={{
                    fontSize: "0.75rem",
                    margin: "0",
                    width: "5rem",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden ",
                  }}
                >
                  {row?.designation}
                </h6>
              </Popover>
              <h6 style={{ fontSize: "0.75rem", margin: "0" }}>
                {row?.employeeId}
              </h6>
            </div>
            <div style={{ width: "2rem" }}>
              {flag ? (
                <img
                  style={{
                    height: "1.5rem",
                    width: "1.8rem",
                    marginTop: "0.5rem",
                    marginLeft: "0.2rem",
                  }}
                  src={ManualIcon}
                />
              ) : null}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "6.8rem",
            marginLeft: "0.3rem",
            fontWeight:"bold"
          }}
        >
          {/* <h4
            style={{
              fontSize: "1rem",
              paddingTop: "0.5rem",
              color: "#696969",
            }}
          >
            Contact Details
          </h4> */}
          Contact Details
        </div>
      ),
      dataIndex: "contactDetails",
      key: "contactDetails",
      // width: "13%",

      render: (val, row) => (
        <div
          style={{
            color: "black",
            width: "100%",
            whiteSpace: "nowrap",
          }}
        >
          <Popover content={row?.email}>
            <h5
              style={{
                fontSize: "1rem",
                width: "7rem",
                textOverflow: "ellipsis",
                overflow: "hidden ",
                cursor: "pointer",
                color: "black",
                margin: "0",
              }}
            >
              {row?.email}
            </h5>
          </Popover>
          <span style={{ fontSize: "0.75rem" }}>{row?.mobile}</span>
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "7rem",
            marginLeft: "0.3rem",
            fontWeight:"bold"
            // border:'1px solid black'
          }}
        >
          {/* <h4
            style={{
              paddingTop: "0.5rem",
              color: "#696969",
              fontSize: "1rem",
            }}
          >
            Work Summary
          </h4> */}
          Work Summary
        </div>
      ),
      // width: "13%",
      dataIndex: "workSummary",
      key: "workSummary",
      render: (val, row) => (
        <div style={{ width: "100%" }}>
          {/* {console.log("sumwork",row)} */}
          {
            <a
              type="text"
              style={{
                color: "black",
                // fontSize: "1rem",
              }}
              onClick={() => onWorkSummaryClick(row)}
            >
              <PlusCircleOutlined style={{ marginRight: "0.3rem" }} />
              <span>Edit Summary</span>
              {/* {val.length > 0 ? (
                <span>Change Summary</span>
              ) : (
                <span>Add Summary</span>
              )} */}
            </a>
          }
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
            gap: "0.5rem",
            width: "7rem",
            marginLeft: "0.3rem",
            fontWeight:"bold"
          }}
        >
          <Popover content={"Associate Level"}>
            <text
              style={{
                margin: "0rem",
                color: "#696969",
                width: "7rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // fontSize: "1rem",
              }}
            >
              Associate Level
            </text>
          </Popover>
          {/* <div>
            <Select
              suffixIcon={
                <AiFillFilter
                  style={{
                    color:
                      designationFilter === "" ||
                      designationFilter === null ||
                      designationFilter === undefined
                        ? "#787b83"
                        : Color.modalblue,
                  }}
                />
              }
              allowClear
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleDesignationFilter}
              removeIcon={true}
            >
              {Object.values(designationList).map((item,index) => (
                <Option key={index} value={item} />
              ))}
            </Select>
          </div> */}
          <div>
            <Select
              suffixIcon={
                <AiFillFilter
                  style={{
                    color:
                      designationFilter === "" ||
                      designationFilter === null ||
                      designationFilter === undefined
                        ? "#787b83"
                        : Color.modalblue,
                  }}
                />
              }
              allowClear={filterLevelChanged}
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleDesignationFilter}
              value={initialFilter}
              removeIcon={true}
            >
              {Object.values(designationList).map((item, index) => (
                <Option key={index} value={item} />
              ))}
            </Select>
          </div>
        </div>
      ),
      key: "designation",
      // width: "14%",

      render: (val, row) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "8rem", fontSize: "1rem" }}>
              {row?.associateLevel}
            </div>
            <div style={{ marginLeft: "-1.5rem" }}>
              <a
                type="primary"
                onClick={() => {
                  dispatch(adminActions.updateModalType("Designation"));
                  openCommonModal(row);
                }}
              >
                <Popover
                  key={row.id}
                  content={designationContent}
                  trigger="hover"
                  visible={hovered === row.id}
                >
                  <img
                    onMouseEnter={() => handleMouseEnterLevel(row?.id)}
                    onMouseLeave={handleMouseLeaveLevel}
                    src={hovered === row.id ? refreshIcon : cycleStatus}
                    style={{ width: "1.2rem" }}
                    alt=""
                  />
                </Popover>
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
            gap: "0.5rem",
            width: "6rem",
            marginLeft: "0.3rem",
            fontWeight:"bold"
          }}
        >
          <Popover content={content}>
            <text
              style={{
                margin: "0rem",
                color: "#696969",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // fontSize: "1rem",
              }}
            >
              Appraisal Cycle
            </text>
          </Popover>
          <div>
            <Select
              suffixIcon={
                <AiFillFilter
                  style={{
                    color:
                      cycleFilter === "" ||
                      cycleFilter === null ||
                      cycleFilter === undefined
                        ? "#787b83"
                        : Color.modalblue,
                  }}
                />
              }
              allowClear={filterCycleChanged}
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleCycleFilter}
              value={initialFilter}
              removeIcon={true}
            >
              {Object.values(cycleList).map((item) =>  (
                <>
                {/* {console.log("cycllll",item?.appraisalCycle)} */}
                <Option value={item?.appraisalCycle} label={item?.appraisalCycle} title="" >
                  {item?.appraisalCycle}
                </Option>
                </>
              ))}
            </Select>
          </div>
        </div>
      ),
      // width: "14%",
      dataIndex: "appraisalCycle",
      key: "appraisalCycle",
      render: (val, row) => {
        // console.log("cyccccc",row)
        return (
          <div
            style={{
              width: "6.5rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: "1rem",
            }}
          >
            <div>{row?.appraisalCycle}</div>
            <div style={{ marginRight: "0.5rem" }}>
              <a
                type="primary"
                onClick={() => {
                  dispatch(adminActions.updateModalType("Appraisal"));
                  openCommonModal(row);
                  console.log("ssRoww")
                }}
              >
                <Popover
                  key={row.id}
                  content={cycleContent}
                  trigger="hover"
                  visible={hoveredCycle === row.id}
                >
                  <img
                    onMouseEnter={() => handleMouseEnterCycle(row?.id)}
                    onMouseLeave={handleMouseLeaveCycle}
                    src={hoveredCycle === row.id ? refreshIcon : cycleStatus}
                    style={{ width: "1.2rem" }}
                    alt=""
                  />
                </Popover>
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
            gap: "0.5rem",
            width: "6rem",
            marginLeft: "0.3rem",
            fontWeight:"bold"
          }}
        >
          <Popover content={content1}>
            <text
              style={{
                margin: "0rem",
                color: "#696969",
                width: "500%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // fontSize: "1rem",
              }}
            >
              Appraisal Form Status
            </text>
          </Popover>
          <div>
            <Select
              suffixIcon={
                <AiFillFilter
                  style={{
                    color:
                      formStatusFilter === "" ||
                      formStatusFilter === null ||
                      formStatusFilter === undefined
                        ? "#787b83"
                        : Color.modalblue,
                  }}
                />
              }
              allowClear={filterFormChanged}
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleFormStatusFilter}
              value={initialFilter}
              removeIcon={true}
            >
              {Object.values(statusList).map((item) => (
                <Option value={item} label={item} title="" />
              ))}
            </Select>
          </div>
        </div>
      ),
      // width: "16.5%",
      dataIndex: "appraisalFormStatus",
      key: "appraisalFormStatus",
      render: (val, row) => {
        return (
          <div
            style={{
              width: "8rem",
              //  fontSize: "1rem"
            }}
          >
            {row?.userStatus}
          </div>
        );
      },
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
            gap: "0.5rem",
            // width: "100%",
            marginLeft: "0.3rem",
            fontWeight:"bold"
          }}
        >
          <Popover content={content2}>
            <text
              style={{
                margin: "0rem",
                color: "#696969",
                // width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // fontSize: "1rem",
              }}
            >
              Appraisal Status
            </text>
          </Popover>

          <Select
            suffixIcon={
              <AiFillFilter
                style={{
                  color:
                    appraisalFilter === "" ||
                    appraisalFilter === null ||
                    appraisalFilter === undefined
                      ? "#787b83"
                      : Color.modalblue,
                }}
              />
            }
            allowClear={filterStatusChanged}
            trigger="click"
            type="text"
            placement="bottomLeft"
            bordered={false}
            dropdownMatchSelectWidth={false}
            onChange={handleAppraisalfilter}
            value={initialFilter}
            removeIcon={true}
          >
            {Object.values(appraisalOptions).map((item) => (
              <Option value={item} label={item} title="" />
            ))}
          </Select>
        </div>
      ),
      // width: "10%",
      dataIndex: "appraisalStatus",
      key: "appraisalStatus",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: "11rem",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Button
                type="text"
                style={{
                  background: row?.appraisalActive ? "#C2E3FF" : "#FFAAAA",
                  color: row?.appraisalActive ? Color.modalblue : Color.red,
                  // borderColor: row?.appraisalActive
                  //   ? Color.modalblue
                  //   : Color.red,
                  width: "6rem",
                  fontSize: "1rem",
                  borderRadius: "4px",
                }}
                onClick={() => onAppraisalStatusClick(row)}
              >
                {row?.appraisalActive ? "Active" : "Inactive"}
              </Button>
              <a style={{position:"relative",right:"1.5rem"}}>
                <Popover
                  key={row.id}
                  content={statusContent}
                  trigger="hover"
                  visible={hoveredStatus === row.id}
                  placement="bottomLeft"
                >
                  <img
                    onMouseEnter={() => handleMouseEnterStatus(row?.id)}
                    onMouseLeave={handleMouseLeaveStatus}
                    src={hoveredStatus === row.id ? refreshIcon : cycleStatus}
                    style={{ width: "1.2rem" }}
                    alt=""
                    onClick={() => onAppraisalStatusClick(row)}
                  />
                </Popover>
              </a>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{}}>
      <Table
        dataSource={users}
        columns={columns}
        rowKey={(record) => record?.id}
        // pagination={pagination}
        // pagination={{
        //   position: "bottomLeft",
        //   pageSize: meta?.itemsPerPage,
        //   size: "small",
        //   pageSizeOptions: [10, 30, 50, 100],
        //   current: Number(meta?.currentPage),
        //   total: meta?.totalItems ? meta?.totalItems : 0,
        //   onChange: (page, pageSize) => handlePageChange(page, pageSize),
        //   showSizeChanger: true,
        //   showTotal: (total, range) =>
        //     `${range[0]}-${range[1]} of ${total} items`,
        // }}
        pagination={{
          position: "bottomLeft", // change the position to "bottomLeft"
          pageSize: pageSize, // set pageSize to 10 to display only 10 records
          size: "small",
          pageSizeOptions: [5, 10, 20, 30, 50],
          current: Number(meta?.currentPage),
          total: meta?.totalItems ? meta?.totalItems : 0,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        // className={classes.userTable}
        // className="test"
        scroll={{
          y: 370,
        }}
        style={{width:"100%",marginTop:"1rem"}}
      />

      <WorkSummaryModal />
      <CommonModal />
    </div>
  );
};

export default UserTable;
