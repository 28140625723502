import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "antd/dist/antd.css"
import PrivateRoute from "components/PrivateRoute";
import { useSelector } from "react-redux";
import Api from "services/Api";
import LoadingModal from "components/Loader";
import AdminRoutesPrivate from "./AdminRoutesPrivate";

function AdminRoutes() {
  const { token } = useSelector((state) => state.auth);
  const { loader } = useSelector((state) => state.global);
  // console.log("coming to routes", user?.role);
  useEffect(() => {
    if (token) {
      Api.header(token);
    }
    // if (user?.id === 3){
    //   console.log("Shakira logged in")
    // }
  }, [token]);

  return (
    <main>
      <AdminRoutesPrivate />
    </main>
  );
}

export default AdminRoutes;
