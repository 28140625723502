import { Button, Modal } from "antd";
import React from "react";
import ReloadIcon from "../../assets/ReloadIcon.svg"

import tickIcon from "../../assets/tickIcon.svg"

function ReviseDataModal(props) {
  const handleTeamOk = () => {
    console.log("apii should be clicked :");
    props.updateReviseModal(false);
  };
  return (
    <div>
      <Modal
        bodyStyle={{ padding: 0, height: "20rem" }}
        open={props?.reviseModal}
        // onCancel={() => {
        //   props.updateReviseModal(false);
        // }}
        // onOk={handleTeamOk}
        closeIcon={true}
        footer={null}
         
      >
         <div
          style={{
            backgroundColor: "#0086FF",
            height: "3.5rem",
            display: "flex",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
        >
          <img src={ReloadIcon} style={{ width: "1.5rem", height: "1.5rem" }} />
          <h3
            style={{
              marginLeft: "0.5rem",
              color: "white",
              fontFamily: "Roboto-Bold",
            }}
          >
            Revise Data
          </h3>
        </div>
        <div>
          <div style={{display:"flex",justifyContent:"center",marginTop:"2rem"}}>
          <img src={tickIcon}  />
          </div>
          <h3 style={{fontSize:"22px",fontWeight:"bold",textAlign:"center",position:"relative",top:"1rem"}}>Data Revised Successfully!</h3>
        </div>
        <div
            style={{
              textAlign: "center",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >           
              <Button
                onClick={handleTeamOk}
                style={{
                  width: "8rem",
                }}
                type="primary"
              >
                Okay
              </Button>
           
          </div>
      </Modal>
    </div>
  );
}

export default ReviseDataModal;
