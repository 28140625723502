import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { useEffect, useState } from "react";
import { managerActions } from "./store";
import { useHistory } from "react-router";
import { Row, Col, Select, Button } from "antd";
import arrowIcon from "../../assets/upperarrow.svg";
import Notification from "services/Notification";
import { globalActions } from "store/loader";

const PerformanceMatrix = () => {
  const { user } = useSelector((state) => state.auth);
  const { associateList, selectedTeamId } = useSelector(
    (state) => state.manager
  );
  const [octoberAssociates, setOctoberAssociatws] = useState([]);
  const { loader } = useSelector((state) => state.global);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!selectedTeamId) {
      history.goBack();
      return;
    }
    dispatch(globalActions.updateLoader({loader:true}))
    Api.get(
      "/manager/associate-list?teamId=" +
        selectedTeamId +
        "&managerId=" +
        user.id
    )
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(
            managerActions.updateAssociateList({
              associates: response.associates,
            })
          );
         
          const octoberAssociates = response.associates.filter(
            (associate) => associate.workSummary_managerStatus !== "Completed"
          );
          
          setOctoberAssociatws(octoberAssociates);
        }
      });
  }, []);

  // const options = ranks?.map((associate, index) => {
  //   return (
  //     <Select.Option key={index} value={associate.user_id}>
  //       {associate.user_name}
  //     </Select.Option>
  //   );
  // })
 
  const onAssociateClick = (value, i, j) => {
    const changedAssociate = {
      user_id: value,
      workSummary_performance: j,
      workSummary_attitude: matrixSize - i + 1,
    };

    dispatch(
      managerActions.updateSingleAssociatePerformanceAttitude({
        associate: changedAssociate,
      })
    );
  };
   
  const submitHandler = () => {
    
    let title=""
    for( const associate of associateList){
     
      if(!(associate.workSummary_performance || associate.workSummary_attitude))
      {
        let text = "Please rank all the associates";
          Notification.error(title,text)
          return 
      }
    }
    Api.post("/manager/team-performance-attitude?teamId=" + selectedTeamId)
      .params({ associateList })
      .send((response, error) => {
        if (typeof response != "undefined") {
          if (response?.show?.type === "success") {
            history.goBack();
          }
          //   dispatch(
          //     managerActions.updateAssociateList({
          //       associates: response.associates,
          //     })
          //   );
          //   setRanks(response.ranks);
        }
      });
  };

  const matrixSize = Math.ceil(Math.sqrt(associateList.length));

  const cards = [];

  for (let i = 1; i <= matrixSize; i++) {
    let cols = [];
    for (let j = 1; j <= matrixSize; j++) {
      let associate = associateList.filter(
        (associate) =>
          associate.workSummary_attitude === matrixSize - i + 1 &&
          associate.workSummary_performance === j
      );
      
      cols.push(
        <Col
          style={{
            cursor: "pointer",
            backgroundColor: "skyblue",
            height: "150px",
            width: "150px",
            textAlign: "center",
            padding: "20px",
            margin: "5px",
          }}
        >
          <Select
          
            value={associate[0]?.user_name}
            style={{ width: 120 }}
            onChange={(value) => onAssociateClick(value, i, j)}
            disabled={associate[0]?.workSummary_managerStatus === "Completed"}
          > 
            {octoberAssociates?.map((associate, index) => {
              return (
                <Select.Option key={index} value={associate.user_id}>
               
                  {associate.user_name}
                </Select.Option>
              );
            })}
          </Select>
          {matrixSize - i + 1}-{j}
        </Col>
      );
    }
    cards.push(
      <Row
        // style={{display:"flex",justifyContent:"center",alignItems:"center"}}
        gutter={[16, 16]}
        // style={{
        //   cursor: "pointer",
        //   backgroundColor: "yellow",
        //   height: "150px",
        //   width: "150px",
        //   textAlign: "center",
        //   padding: "20px",
        // }}
      >
        {cols}
      </Row>
    );
  }
  if(loader)
  {
    return null;
  }

  return (
    <>
      <br></br>

      <Row justify="end" align="middle">
        <Col xs={{ span: 4 }} xl={{ span: 4 }} style={{ paddingLeft: "15px" }}>
          <h1 className="transform" style={{ color: "#008080" }}>
            Attitude
            <img
              src={arrowIcon}
              alt=""
              style={{ width: "50px", display: "inline" }}
            ></img>
          </h1>
        </Col>
        <Col
          xs={{ span: 20 }}
          xm={{ span: 20 }}
          xl={{ span: 20 }}
          style={{ paddingLeft: "18px" }}
        >
          <Row>
            <Col>
              <h1 style={{ color: "#008080" }}>Performance Attitude:</h1>
              {cards}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={4}></Col>
        <Col span={20}>
          <h1
            style={{ marginTop: "50px", color: "#008080", paddingLeft: "50px" }}
          >
            Performance{" "}
            <img
              src={arrowIcon}
              alt=""
              style={{ width: "50px", display: "inline", color: "green" }}
            ></img>
          </h1>
        </Col>
      </Row>
      <Row type="flex" align="middle" style={{paddingBottom:"20px"}}>
        <Col Xl={18} md={18} xs={24}></Col>
        <Col  Xl={6} md={6} xs={24} >
          {" "}
          {/* <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}> */}
          <Button type="primary" style={{ background: "#1890ff", borderColor: "skyblue" }} onClick={submitHandler}>Submit</Button>{" "}
          <Button onClick={() => history.goBack()}>Back</Button>
        </Col>
      </Row>
    </>
  );
};

export default PerformanceMatrix;
