import React, { useEffect, useState } from "react";
import { Button, Table, Col, Input, Row, Popover, Select, Search } from "antd";
import { withRouter } from "react-router-dom";

import DownloadIcon from "../../assets/download .svg";
import SearchIcon from "../../assets/search icon.svg";
import { PlusCircleTwoTone } from "@ant-design/icons";
import { AiFillFilter, AiFillCaretUp, AiFillCaretDown } from "services/Icons";
import Api from "services/Api";
import { Color } from "services/color";
import { useDispatch, useSelector } from "react-redux";

import TeamModal from "./commonTeam-modal";
import "./tableHeader.css";
import "antd/dist/antd.css";
import pluseIcon from "../../assets/pluse-circle.svg";
import EditIcon from "../../assets/editIcon.svg";
import { adminActions } from "./store";

function Team() {
  const { refreshEffect,sortingTeam,sortingManager,searchText } = useSelector((state) => state.admin);
  const [showEditModal, updateShowEditModal] = useState(false);
  const [TeamModalOpen, updateTeamModalOpen] = useState(false);
  const [departmentModalOpen, updateDepartmentModalOpen] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [details, updateDetails] = useState();
  const [teamModalName, updateTeamModalName] = useState();
  const [downloadTeam, updateDownloadTeam] = useState("false");
  const [depDropDown, setDepDropDown] = useState();
  const [filterDepId, setFilterDepId] = useState();
  const [page, setPage] = useState(1);

  const [teamNameModal, SetTeamNameModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(10);
  

  const dispatch  = useDispatch();

  const searchData =
  searchText === "" || typeof searchText === undefined
    ? ""
    :`&search=${searchText}`;


  const getTableData = () => {
    setLoader(true);
    // dispatch(adminActions.updatePage({ page: 1, pageSize: pageSize }));
    Api.get(`/admin/teams?${searchData}`)
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setTeamData(response);
          // console.log("Team users", response);
          
        }
      });
  };
  const departmentDropDown = () => {
    Api.get(`/admin/getDepartmentDropdown`)
      .params()
      .send((response, error) => {
        if (typeof response !== "undefined") {
          console.log("Dropdown management", response);
          // props?.refresh();
          setDepDropDown(response);
        }
      });
  };

  useEffect(() => {
    getTableData();
    departmentDropDown();
  }, [searchText]);
  
 

  const handleSearchInput = (e) => {
    // console.log("search text", e.target.value);
    // console.log("sssPage",page,pageSize);
    // dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
      setSearchQuery(e.target.value);
      setTimeout(() => {
        Api.get(`/admin/teams?search=${searchQuery}`).send((response, error) => {
          if (typeof response !== "undefined") {
            setLoader(false);
            setTeamData(response);
            console.log("Team Search users", response);
          }
          
        });
      }, 1000);
   
  };

  const handleSearchTeam = () => {
    // dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    // console.log("sssPage",page,pageSize);
    setLoader(true);
    Api.get(`/admin/teams?search=${searchQuery}`).send((response, error) => {
      if (typeof response !== "undefined") {
        setLoader(false);
        setTeamData(response);
        console.log("Team Search users", response);
      }
      
    });
  };

  const handleDownload = () => {
    setLoader(true);
    Api.get(`/admin/teams?downloadTeam=${true}&search=${searchQuery}&departmentId=${filterDepId}`)
      // .params({departmentId:filterDepId})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          const url = response;
          const link = document.createElement("a");
          link.href = url;
          link.download = "allData.csv"; // set the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          console.log("Download users", response);
          setLoader(false);
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchTeam();
    }
  };

  const sortAscending = <h4>Click to sort ascending </h4>;
  const sortDescending = <h4>Click to sort descending </h4>;

  const handleSortByTeam = (value) => {
    // console.log("ClickedTeam", value);
    value === sortingTeam
    ? dispatch(adminActions.updateSortFilterTeam({ ...{}, sortByName: undefined }))
    : dispatch(adminActions.updateSortFilterTeam({ ...{}, sortByName: value }));
    // console.log("Sorting Filter by name", value);
    setLoader(true);
    Api.get(`/admin/teams`, {
      sort: value,
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setTeamData(response);
          // console.log("Team users", response);
        }
      });
  };

  const handlSortByManager = (value) => {
    // console.log("managersort", value);
    value === sortingManager
    ? dispatch(adminActions.updateSortFilterManager({ ...{}, sortByName: undefined }))
    : dispatch(adminActions.updateSortFilterManager({ ...{}, sortByName: value }));
    console.log("Sorting Filter by name", value);
    setLoader(true);
    Api.get(`/admin/teams`, {
      sort: value,
      sortBy: "manager",
    })
      // .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setTeamData(response);
          // console.log("Team users", response);
        }
      });
  };

  // console.log("teamdata ",teamData);

  const handleDepartmentFilter = (value) => {
    console.log("ffffdep", value);
    setFilterDepId(value);
    Api.get(`/admin/teams?departmentId=${value}`)
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          setLoader(false);
          setTeamData(response);
          console.log("Team users", response);
        }
      });
  };

  const columns = [
    {
      title: (
        <div style={{ display: "flex", color: "#696969", textAlign: "center",fontWeight:"bold",marginLeft: "0.3rem" }}>
          Department
          <span
            style={{
              position: "relative",
              top: "-2px",
              // border:"1px solid black"
            }}
          >
            <Select
              style={{ width: 200, textAlign: "center" }}
              bordered={false}
              defaultValue="All Department"
              onChange={handleDepartmentFilter}
              suffixIcon={
                <AiFillFilter
                  style={{
                    color: "#999999",
                  }}
                />
              }
            >
              <Option value="">All Department</Option>
              {depDropDown &&
                Object.values(depDropDown).map((item) => (
                  <Option
                    key={item?.id}
                    value={item?.id}
                    label={item?.name}
                    title={item?.name}
                  >
                    {item?.name}
                  </Option>
                ))}
            </Select>
          </span>
        </div>
      ),
      children: [
        {
          title: (
            <div style={{ marginLeft: "0.3rem",fontWeight:"bold" }}>
              Team Name
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "0px",
                  width: "10rem",
                  // position: "relative",
                  bottom: "25px",
                  marginLeft: "2rem",
                }}
              >
                <Popover content={sortAscending}>
                  <Button
                    type="text"
                    style={{
                      height: "0.6rem",
                      margin: "0",
                      padding: "0",
                      width: "20px",
                      marginLeft: "4rem",
                      bottom: "25px",
                      color: "#787b83",
                    }}
                    onClick={() => handleSortByTeam("ASC")}
                  >
                    <AiFillCaretUp
                      style={{
                        color:
                          sortingTeam === "" ||
                          sortingTeam === null ||
                          sortingTeam === undefined
                            ? "#787b83"
                            : sortingTeam === "ASC"
                            ? Color.modalblue
                            : "#787b83",
                      }}
                    />
                  </Button>
                </Popover>
                <Popover content={sortDescending}>
                  <Button
                    type="text"
                    style={{
                      height: "0.8rem",
                      margin: "0",
                      padding: "0",
                      width: "20px",
                      marginLeft: "4rem",
                      color: "#787b83",
                      bottom: "25px",
                    }}
                    onClick={() => handleSortByTeam("DESC")}
                  >
                    {<AiFillCaretDown 
                      style={{
                        color:
                          sortingTeam === "" ||
                          sortingTeam === null ||
                          sortingTeam === undefined
                            ? "#787b83"
                            : sortingTeam === "DESC"
                            ? Color.modalblue
                            : "#787b83",
                      }}
                    />}
                  </Button>
                </Popover>
              </div>
            </div>
          ),
          dataIndex: "name",
          key: "name",
          width: "20rem",
          render: (val, row) => {
            // console.log("xzxzxz", row?.id);

            return (
              <span
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => {
                  SetTeamNameModal(true);
                  updateTeamModalName(row, row?.id);
                }}
              >
                {row?.name}
              </span>
            );
          },
        },
        {
          title: (
            <div style={{ marginLeft: "0.3rem",fontWeight:"bold" }}>
              Manager
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "0px",
                  width: "10rem",
                  // position: "relative",
                  bottom: "25px",
                }}
              >
                <Popover content={sortAscending}>
                  <Button
                    type="text"
                    style={{
                      height: "0.6rem",
                      margin: "0",
                      padding: "0",
                      width: "20px",
                      marginLeft: "5rem",
                      bottom: "25px",
                      color: "#787b83",
                    }}
                    onClick={() => handlSortByManager("ASC")}
                  >
                    <AiFillCaretUp  style={{
                        color:
                          sortingManager === "" ||
                          sortingManager === null ||
                          sortingManager === undefined
                            ? "#787b83"
                            : sortingManager === "ASC"
                            ? Color.modalblue
                            : "#787b83",
                      }} />
                  </Button>
                </Popover>
                <Popover content={sortDescending}>
                  <Button
                    type="text"
                    style={{
                      height: "0.8rem",
                      margin: "0",
                      padding: "0",
                      width: "20px",
                      marginLeft: "5rem",
                      color: "#787b83",
                      bottom: "25px",
                    }}
                    onClick={() => handlSortByManager("DESC")}
                  >
                    {<AiFillCaretDown  style={{
                        color:
                          sortingManager === "" ||
                          sortingManager === null ||
                          sortingManager === undefined
                            ? "#787b83"
                            : sortingManager === "DESC"
                            ? Color.modalblue
                            : "#787b83",
                      }} />}
                  </Button>
                </Popover>
              </div>
            </div>
          ),
          dataIndex: "manager",
          key: "manager",
          width: "20rem",
          render: (val, row) => {
            return <>{row.manager?.name}</>;
          },
        },
        {
          title: <div style={{ marginLeft: "0.3rem",fontWeight:"bold" }}>Action</div>,
          dataIndex: "edit",
          key: "edit",
          width: "20rem",
          render: (val, row) => {
            // console.log("roodata",row);
            return (
              <Button
                style={{
                  backgroundColor: "#FAFAFA",

                  border: "none",
                }}
                shape="circle"
                onClick={() => {
                  updateShowEditModal(true);
                  updateDetails(row);
                }}
              >
                <img src={EditIcon} />
              </Button>
            );
          },
        },
      ],
    },
  ];

  const handlePageChange=(page,pageSize) => {
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
    // dispatch(adminActions.updatePage({ page: page, pageSize: pageSize }));
    setTimeout(() => {
      console.log("pagination",page,pageSize)
      setPageSize(pageSize);
      setPage(page);
    }, 1500);
  }

  

  return (
    <div style={{ position: "fixed" }}>
      <div
        style={{
          width: "87%",
          height: "80%",
          marginLeft: "11rem",
          marginTop: "-1rem",
          display: "flex",
        }}
      >
        <>
          <div
            style={{
              width: "31%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // border:"1px solid black"
            }}
          >
            <Col>
              <h4
                style={{
                  fontSize: "1.5rem",
                  fontFamily: "Roboto-Bold",
                  position:"relative",
                  top:"6px"
                }}
              >
                Team List
              </h4>
            </Col>
            <Col>
              <Input
                style={{
                  width: "15rem",
                  height: "2.2rem",
                  
                }}
                autoFocus={true}
                placeholder="Search"
                allowClear={true}
                onChange={(e) => {
                  // console.log("Search Value", e.target.value);
                  setTimeout(() => {
                    // setSearchText(e.target.value);
                    setPage(1)
                    dispatch(adminActions.updateSearchText(e.target.value));
                  }, 1000);
                }}
                prefix={
                  // <Button
                  //   type="text"
                  //   style={{ padding: "0", margin: "0" }}
                  //   onClick={() => {
                  //     dispatch(
                  //       adminActions.updateRefreshEffect((olddata) => olddata + 1)
                  //     );
                  //     dispatch(adminActions.updatePage(e.target.value));
                  //   }}
                  // >
                    <img
                      style={{ width: "1.5rem", height: "1.5rem" }}
                      src={SearchIcon}
                    />
                  // </Button>
                }
              />
            </Col>
          </div>
          <div
            style={{
              width: "40%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginLeft: "20rem",
            }}
          >
            <Col>
              <Button
                style={{
                  backgroundColor: "#0086FF",
                  border: "none",
                  borderRadius: "4px",
                  color: "white",
                  width: "9rem",
                  fontSize: "14px",

                  // marginLeft:"10rem"
                }}
                onClick={() => {
                  updateDepartmentModalOpen(true);
                }}
              >
                <img
                  src={pluseIcon}
                  style={{
                    width: "0.9rem",
                    // height: "3rem",
                    marginRight: "0.3rem",
                    marginBottom: "0.1rem",
                  }}
                />
                Add Department
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  backgroundColor: "#0086FF",
                  border: "none",
                  borderRadius: "4px",
                  color: "white",
                  width: "7rem",
                  fontSize: "14px",

                  // marginLeft:"10rem"
                }}
                onClick={() => {
                  updateTeamModalOpen(true);
                }}
              >
                <img
                  src={pluseIcon}
                  style={{
                    width: "0.9rem",
                    // height: "3rem",
                    marginRight: "0.3rem",
                    marginBottom: "0.1rem",
                  }}
                />
                Add Team
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  fontSize: "14px",
                  color: "white",
                  backgroundColor: "#3BCF71",
                  borderRadius: "4px",
                  border: "none",
                }}
                onClick={handleDownload}
              >
                Download Team Data
                <img
                  style={{
                    width: "1.2rem",
                    height: "1.2rem",
                    marginLeft: "0.5rem",
                    alignItems: "center",
                  }}
                  src={DownloadIcon}
                  alt=""
                />
              </Button>
            </Col>
          </div>
        </>
      </div>
      <div
        style={{
          width: "86%",
          height: "80%",
          marginLeft: "11rem",
          marginTop: "1rem",
        }}
      >
        <div>
          <Table
            dataSource={teamData}
            // loading={loader}
            columns={columns}
            // className="test"
            // style={{height:"70%"}}
            pagination={{
              size: "small",
              total: teamData.length,
              pageSize:pageSize,
              current:page,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              // defaultPageSize: 10,
              // defaultCurrent: defaultPage,
              // position: ["bottomLeft"],
              onChange: (page, pageSize) => handlePageChange(page, pageSize),
            }}
            bordered
            scroll={{
              y: 300,
            }}
          />
        </div>
        {TeamModalOpen && (
          <TeamModal
            refresh={getTableData.bind(this)}
            TeamModalOpen={TeamModalOpen}
            updateTeamModalOpen={updateTeamModalOpen}
            dataDrop={depDropDown}
          />
        )}
        {showEditModal && (
          <TeamModal
            refresh={getTableData.bind(this)}
            showEditModal={showEditModal}
            updateShowEditModal={updateShowEditModal}
            data={details}
            data2={depDropDown}
          />
        )}
        {teamNameModal && (
          <TeamModal
            teamNameModal={teamNameModal}
            SetTeamNameModal={SetTeamNameModal}
            data1={teamModalName}
          />
        )}
        {departmentModalOpen && (
          <TeamModal
            departmentModalOpen={departmentModalOpen}
            updateDepartmentModalOpen={updateDepartmentModalOpen}
            //  dataDepartment={}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(Team);
